import type { IconName } from 'components/common/Icon';

export type RouteObject = {
  id: string;
  path: string;
  pathName: string;
  iconName?: IconName;
  title: string;
  end: boolean;
  sideBar: boolean;
  permissions: string[];
};

export const securityRoutes: RouteObject[] = [
  {
    id: 'security-main',
    path: '/security',
    pathName: 'SECURITY',
    iconName: 'poll',
    title: 'Overview',
    end: true,
    sideBar: true,
    permissions: ['graylog_security:read'],
  },
  {
    id: 'security-user-activity',
    path: '/security/user-activity',
    pathName: 'SECURITY_USER-ACTIVITY',
    iconName: 'user',
    title: 'User Activity',
    end: false,
    sideBar: true,
    permissions: ['graylog_security:read'],
  },
  {
    id: 'security-host-activity',
    path: '/security/host-activity',
    pathName: 'SECURITY_HOST-ACTIVITY',
    iconName: 'tv',
    title: 'Host Activity',
    end: false,
    sideBar: true,
    permissions: ['graylog_security:read'],
  },
  {
    id: 'security-network-activity',
    path: '/security/network-activity',
    pathName: 'SECURITY_NETWORK-ACTIVITY',
    iconName: 'wifi',
    title: 'Network Activity',
    end: false,
    sideBar: true,
    permissions: ['graylog_security:read'],
  },
  {
    id: 'security-investigations',
    path: '/security/investigations',
    pathName: 'SECURITY_INVESTIGATIONS',
    iconName: 'puzzle-piece',
    title: 'Investigations',
    end: false,
    sideBar: true,
    permissions: ['investigations:read'],
  },
  {
    id: 'security-anomalies',
    path: '/security/anomalies',
    pathName: 'SECURITY_ANOMALIES',
    iconName: 'search-plus',
    title: 'Anomalies',
    end: false,
    sideBar: true,
    permissions: ['anomaly_configuration:read'],
  },
  {
    id: 'security-sigma',
    path: '/security/sigma',
    pathName: 'SECURITY_SIGMA',
    iconName: 'ruler',
    title: 'Sigma Rules',
    end: false,
    sideBar: true,
    permissions: ['sigma_rules:read'],
  },
];
