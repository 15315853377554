import * as React from 'react';

import BootstrapModalConfirm from 'components/bootstrap/BootstrapModalConfirm';
import {
  SelectedInvestigationsProvider,
  PaginationProvider,
} from 'security-app/components/Investigations/contexts';
import NewInvestigation from 'security-app/components/Investigations/NewInvestigation';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';

import SearchRow from './Search';
import List from './List';

type Props = {
  type: 'logs' | 'dashboards' | 'searches' | 'events',
  id: string,
  index?: string,
};

const modalTitleByType = {
  logs: 'Add log',
  dashboards: 'Add dashboard',
  searches: 'Add search',
  events: 'Add event',
};

const AddEvidenceModal = React.forwardRef(({ type, id, index }: Props, ref: React.MutableRefObject<ModalHandler>) => {
  const newModalRef = React.useRef<ModalHandler>(null);
  const listRef = React.useRef<ModalHandler>(null);
  const [showModal, setShowModal] = React.useState(false);

  React.useLayoutEffect(() => {
    if (listRef && showModal) {
      const modalDialog = document.querySelector('.modal-dialog') as HTMLElement;
      modalDialog.style.width = '700px';
    }
  }, [listRef, showModal]);

  const coloseModal = () => {
    setShowModal(false);
  };

  const onConfirm = () => {
    listRef.current.onConfirm();
    setShowModal(false);
  };

  React.useImperativeHandle(ref, () => ({
    toggle: () => setShowModal(true),
  }));

  return (
    <>
      <BootstrapModalConfirm showModal={showModal}
                             onCancel={coloseModal}
                             onConfirm={onConfirm}
                             title={`Investigations - ${modalTitleByType[type]} as evidence`}>
        <PaginationProvider>
          <SelectedInvestigationsProvider>
            <SearchRow onNewInvestigation={coloseModal} newModalRef={newModalRef} />
            <List type={type} id={id} index={index} ref={listRef} />
          </SelectedInvestigationsProvider>
        </PaginationProvider>
      </BootstrapModalConfirm>
      <NewInvestigation ref={newModalRef} onClose={() => setShowModal(true)} />
    </>
  );
});

AddEvidenceModal.defaultProps = {
  index: undefined,
};

export default AddEvidenceModal;
