import React, { useCallback, useReducer } from 'react';
import styled from 'styled-components';

import { HoverForHelp } from 'components/common';
import { ButtonGroup } from 'components/bootstrap';
import SelectedFiltersOverview from 'search-filter/components/search-filter-bar/SelectedFiltersOverview';
import SearchFiltersOverview from 'search-filter/components/search-filter-bar/SearchFiltersOverview';
import SearchFilterCreate from 'search-filter/components/search-filter-bar/SearchFilterCreate';
import { SearchFilterExplanation } from 'views/components/searchbar/SearchFilterBanner';
import useSearchFiltersFormState from 'search-filter/hooks/useSearchFiltersFormState';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`;

const Controls = styled.div(({ $displaySpacer }) => `
  display: flex;
  align-items: center;
  margin-right: ${$displaySpacer ? '5x' : 0};
`);

const StyledButtonGroup = styled(ButtonGroup)`
  margin-left: 5px;
`;

const SearchFilterHelp = styled(HoverForHelp)`
  margin-left: 5px;
`;

const showPopoverReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_CREATE_POPOVER':
      return ({ showCreatePopover: !state.showCreatePopover, showLoadPopover: false });
    case 'TOGGLE_LOAD_POPOVER':
      return ({ showCreatePopover: false, showLoadPopover: !state.showLoadPopover });
    default:
      return state;
  }
};

const SearchFilterBar = () => {
  const selectedFilters = useSearchFiltersFormState();
  const sendTelemetry = useSendTelemetry();
  const [{ showCreatePopover, showLoadPopover }, dispatchShowPopover] = useReducer(showPopoverReducer, {
    showCreatePopover: false,
    showLoadPopover: false,
  });

  const toggleShowCreatePopover = useCallback(() => {
    dispatchShowPopover({ type: 'TOGGLE_CREATE_POPOVER' });

    sendTelemetry('click', {
      app_pathname: 'search',
      app_section: 'search-filter',
      app_action_value: 'create',
    });
  }, [sendTelemetry]);
  const toggleShowLoadPopover = useCallback(() => {
    dispatchShowPopover({ type: 'TOGGLE_LOAD_POPOVER' });

    sendTelemetry('click', {
      app_pathname: 'search',
      app_section: 'search-filter',
      app_action_value: 'create',
    });
  }, [sendTelemetry]);

  return (
    <Container>
      <Controls $displaySpacer={!!selectedFilters?.size}>
        Filters
        <SearchFilterHelp title="Search Filters" trigger={['click']}>
          <SearchFilterExplanation />
        </SearchFilterHelp>
        <StyledButtonGroup>
          <SearchFilterCreate showPopover={showCreatePopover}
                              toggleShowPopover={toggleShowCreatePopover} />
          <SearchFiltersOverview showPopover={showLoadPopover}
                                 toggleShowPopover={toggleShowLoadPopover} />
        </StyledButtonGroup>
      </Controls>
      <SelectedFiltersOverview />
    </Container>
  );
};

export default SearchFilterBar;
