import * as React from 'react';

import { Table } from 'components/bootstrap';
import { PaginatedList, Spinner } from 'components/common';
import { FiltersRow } from 'security-app/components/common';
import { usePagination, useSetPagination, useSelectedInvestigations } from 'security-app/components/Investigations/contexts';
import { useGetInvestigations, useAddEvidence } from 'security-app/hooks/useInvestigationsAPI';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';

import ListHeader from './ListHeader';
import ListRows from './ListRows';

type Props = {
  type: 'logs' | 'dashboards' | 'searches' | 'events',
  id: string,
  index?: string,
};

const List = React.forwardRef(({ type, id, index }: Props, ref: React.Ref<ModalHandler>) => {
  const archived = false;
  const localPagination = usePagination();
  const setLocalPagination = useSetPagination();
  const selectedInvestigations = useSelectedInvestigations();

  const { addEvidence } = useAddEvidence();

  const submitEvidence = async (investigation: InvestigationAPIType) => {
    switch (type) {
      case 'logs':
        await addEvidence({
          invId: investigation.id,
          payload: { logs: [{ id, index }] },
        });

        break;
      case 'dashboards':
      case 'searches':
      case 'events':
        await addEvidence({
          invId: investigation.id,
          payload: { [type]: [id] },
        });

        break;
      default:
        break;
    }
  };

  const addEvidenceToSelectedInvestigations = async () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const investigation of selectedInvestigations) {
      // eslint-disable-next-line no-await-in-loop
      await submitEvidence(investigation);
    }
  };

  React.useImperativeHandle(ref, () => ({
    onConfirm: () => addEvidenceToSelectedInvestigations(),
  }));

  const { investigations, pagination, loadingInvestigations } = useGetInvestigations(localPagination, archived);

  const onPageChange = (newPage: number, newPageSize: number) => {
    setLocalPagination({ ...localPagination, page: newPage, perPage: newPageSize });
  };

  const removeFilter = (filterKey: string) => (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    const auxFilters = { ...localPagination.filters };
    delete auxFilters[filterKey];

    setLocalPagination({ ...localPagination, filters: auxFilters });
  };

  return (
    <PaginatedList activePage={localPagination.page}
                   pageSize={localPagination.perPage}
                   onChange={onPageChange}
                   totalItems={pagination.total}
                   useQueryParameter={false}>
      <FiltersRow filters={localPagination.filters} onRemove={removeFilter} />
      <Table condensed hover className="table table-striped" data-testid="open-investigations-list">
        <ListHeader investigations={investigations} />
        <tbody>
          {loadingInvestigations ? <tr><td colSpan={2}><Spinner text="Loading investigations ..." /></td></tr> : (
            <ListRows investigations={investigations} />
          )}
        </tbody>
      </Table>
    </PaginatedList>
  );
});

List.defaultProps = {
  index: undefined,
};

export default List;
