import styled, { css } from 'styled-components';

import securityContentThemeVariables from 'security-app/theme/constants';

function renderPadding(sideNavIsOpen: boolean): string {
  if (sideNavIsOpen) return `${securityContentThemeVariables.measures.SIDE_NAV_WIDTH}px`;

  return '0px';
}

const ContentArea = styled.div<{ sideNavIsOpen: boolean; }>(({ sideNavIsOpen, theme }) => css`
  display: flex;
  flex-direction: column;
  padding-left: ${renderPadding(sideNavIsOpen)};
  padding-top: 15px;
  transition: all 0.33s ease-in-out;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 10;
  top: 0;
  background: ${theme.colors.global.background};
`);

export default ContentArea;
