import * as React from 'react';
import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import { Icon } from 'components/common';

import { formatDate } from './DateFilter';
import type { ColumnFilterData, FilterData } from './ColumnFilter.types';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: .6rem;
  max-width: 75vw;
  font-size: .8rem;
`;

const Pill = styled.span(({ theme }: { theme: DefaultTheme }) => css`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: .3rem;
  line-height: 1;
  border-radius: 15px;
  padding: 5px 10px;
  background-color: ${theme.colors.global.contentBackground};
`);

const RemoveFilter = styled(Icon)(({ theme }: { theme: DefaultTheme }) => css`
  padding-left: 5px;
  cursor: pointer;
  color: ${theme.colors.variant.default};
`);

type Props = {
  filters: ColumnFilterData,
  labels?: { [key: string]: string },
  onRemove: (arg: string) => (e: React.BaseSyntheticEvent) => void,
};

const FiltersRow = ({ filters, labels, onRemove }: Props) => {
  const isDate = (value: string) => {
    return new Date(value).toString() !== 'Invalid Date'
      && !!value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);
  };

  const toLabel = (text: string) => {
    return text.replace(/(\w?)|(_\w?)/, (char: string) => char.toUpperCase()).replaceAll('_', ' ');
  };

  return (
    <Row>
      {Object.entries(filters).map(([column, fData]: [string, FilterData[]], i: number) => (
        <React.Fragment key={`filter-${column}`}>
          {i > 0 ? <i>and</i> : null}
          <Pill><b>{labels[column] ? labels[column] : toLabel(column)}:</b> {fData.map((data: FilterData, j: number) => (
            <React.Fragment key={`fvalue-${data.label}`}>
              {j > 0 ? <i>{isDate(data.label) ? 'to' : 'or'}</i> : null}
              <span>{isDate(data.label) ? formatDate(data.label) : data.label}</span>
            </React.Fragment>
          ))} <RemoveFilter name="times" size="xs" onClick={onRemove(column)} />
          </Pill>
        </React.Fragment>
      ))}
    </Row>
  );
};

FiltersRow.defaultProps = {
  labels: {},
};

export default FiltersRow;
