import * as React from 'react';

import InvestigationsMenu from './CoreInvestigationsMenu';

const InvestigationsMessageAction = ({ id, index }: { id: string, index: string }) => (
  <InvestigationsMenu index={index}
                      id={id}
                      type="logs"
                      bsSize="small"
                      title="Investigations" />
);

export default InvestigationsMessageAction;
