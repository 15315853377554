import type { BulkActions } from 'security-app/types';

const API_PLUGIN_ROUTE = '/plugins/org.graylog.plugins';

const EnterpriseApiRoutes = {
  Illuminate: {
    bundles: {
      index: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles` }),
      one: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}` }),
      status: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/status` }),
      upload: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/upload` }),
      packs: (bundleId: string) => ({
        index: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}/packs` }),
        update: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}/packs` }),
      }),
      hub: {
        index: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub` }),
        latest: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub/latest` }),
        install: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub/${bundleId}` }),
        releaseNotes: (version: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub/release_notes/${version}` }),
      },
    },
  },
  SecurityApp: {
    anomalyConfiguration: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/config` }),
    updateAnomalyConfiguration: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/update_status` }),
    fetchDetectorDefinition: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/config/${id}` }),
    updateDetectorDefinition: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/config/${id}` }),
    createDetectorDefinition: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/config` }),
    deleteDetectorDefinition: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp/ad/detectors/config/${id}` }),
    createSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules` }),
    validateSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/validate` }),
    allSigmaRules: (options: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules?${options}` }),
    oneSigmaRule: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/${id}` }),
    importRepoSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/import` }),
    toggleSigmaRule: (id: string, action: 'enable' | 'disable') => (
      { url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/${id}/${action}` }
    ),
    sigmaBulkOperation: (action: BulkActions) => (
      { url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/bulk/${action}` }
    ),
    sigmaGitRepos: (options?: string) => ({
      url: options
        ? `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/?${options}`
        : `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/`,
    }),
    sigmaGitRepo: (repoId: string, path: string = '') => ({
      url: path
        ? `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/${repoId}?path=${path}`
        : `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/${repoId}`,
    }),
    allSigmaRepos: (options: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories?${options}` }),
    bulkDeleteSigmaRepos: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/bulk/delete/` }),
    defaultSigmaRepo: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/default` }),
    importAllRules: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/import_all` }),
    refreshRepoRules: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/refresh/${id}` }),
    Investigations: {
      index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations?${params}` }),
      update: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations` }),
      archive: (invId: string, archive: boolean) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/archive/${invId}/${archive}` }),
      bulkArchive: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/archive` }),
      new: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations` }),
      priorities: {
        index: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities?page=1&per_page=10000&sort=text&direction=asc` }),
        add: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities` }),
        reprioritize: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/reprioritize` }),
        update: (priorityId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/${priorityId}` }),
        delete: (priorityId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/${priorityId}` }),
        setDefault: (priorityId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/${priorityId}/default` }),
        removeDefault: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/default` }),
      },
      statuses: {
        index: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses?page=1&per_page=10000&sort=status&direction=asc` }),
        add: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses` }),
        update: (statusId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/${statusId}` }),
        delete: (statusId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/${statusId}` }),
        setDefault: (statusId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/${statusId}/default` }),
        removeDefault: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/default` }),
      },
      singleInvestigation: (invId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}` }),
      bulkDelete: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/bulk/delete` }),
      addEvidenceToInvestigation: (invId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/evidence/${invId}` }),
      removeEvidenceFromInvestigation: (invId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/evidence/remove/${invId}` }),
      addNoteToInvestigation: (invId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}/notes` }),
      editInvestigationNote: (invId: string, noteId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}/notes/${noteId}` }),
      deleteInvestigationNote: (invId: string, noteId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}/notes/${noteId}` }),
      fetchUsers: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/users` }),
      fetchTeams: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/teams` }),
    },
    Teams: {
      index: () => ({ url: `${API_PLUGIN_ROUTE}.security/teams?page=1&per_page=10000` }),
    },
  },
  ContentUI: {
    eventTypeCategory: () => ({ url: `${API_PLUGIN_ROUTE}.contentui/event_type_category/ui_mapping` }),
    externalValueActions: () => ({ url: `${API_PLUGIN_ROUTE}.contentui/external_actions/ui_mapping` }),
  },
};

export default EnterpriseApiRoutes;
