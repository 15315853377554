import PropTypes from 'prop-types';
import React from 'react';
import trim from 'lodash/trim';
import split from 'lodash/split';
import uniq from 'lodash/uniq';
import defaultTo from 'lodash/defaultTo';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import last from 'lodash/last';
import slice from 'lodash/slice';

import { Link } from 'components/common/router';
import UserNotification from 'util/UserNotification';
import { Alert, Button, ButtonToolbar, Col, Row, Table, Input } from 'components/bootstrap';
import { IfPermitted, PaginatedList, SearchForm, TimeUnitInput, Timestamp } from 'components/common';
import Routes from 'routing/Routes';
import ObjectUtils from 'util/ObjectUtils';
import connect from 'stores/connect';
import LicenseCheck from 'enterprise/parameters/components/LicenseCheck';
import { ViewsLicenseActions as LicenseActions } from 'enterprise/parameters/stores/ViewsLicenseStore';

import type { PaginatedEntries } from '../stores/MongoDBDataAdapterStore';
import { MongoDBDataAdapterStore, MongoDBDataAdapterActions } from '../stores/MongoDBDataAdapterStore';
import MongoDBDataAdapterEntry from '../models/MongoDBDataAdapterEntry';

type Props = {
  data: PaginatedEntries,
  dataAdapterId: string,
};

type State = {
  storeId: string,
  storeKey: string,
  storeValues: Array<string>,
  editMode: boolean,
  ttl: {
    value: number,
    unit: string,
    enabled: boolean,
  },
  query: string,
  page: number,
  perPage: number,
};

const NoValidLicense = ({ license }) => {
  const errorMessage = license && license.error ? (
    <p>The following error has occurred while retrieving the license: {license.error}</p>
  ) : null;

  return (
    <Row>
      <Col md={12}>
        <Alert bsStyle="danger">
          <h4 style={{ fontWeight: 'bold', marginBottom: 5 }}>Modification of entries disabled</h4>
          <p>
            The modification of entries in the database is disabled because there is no valid license installed.
          </p>
          {errorMessage}
          <IfPermitted permissions="licenses:create">
            <p>
              See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
            </p>
          </IfPermitted>
        </Alert>
      </Col>
    </Row>
  );
};

NoValidLicense.propTypes = {
  license: PropTypes.shape({
    error: PropTypes.any,
  }),
};

NoValidLicense.defaultProps = {
  license: {
    error: 'No valid license exists.',
  },
};

class MongoDBDataAdapterTable extends React.Component<Props, State> {
  static propTypes = {
    dataAdapterId: PropTypes.string,
    data: PropTypes.exact({
      pagination: PropTypes.exact({
        total: PropTypes.number.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        count: PropTypes.number.isRequired,
        page: PropTypes.number.isRequired,
        perPage: PropTypes.number.isRequired,
      }).isRequired,
      entries: PropTypes.arrayOf(PropTypes.instanceOf(MongoDBDataAdapterEntry)).isRequired,
    }),
  };

  static defaultProps = {
    data: {
      entries: [],
      pagination: {
        total: 0,
        count: 0,
        page: 0,
        perPage: 0,
      },
    },
    dataAdapterId: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      page: 1,
      perPage: 10,
      storeId: '',
      storeKey: '',
      ttl: {
        value: 1,
        unit: '',
        enabled: false,
      },
      storeValues: [],
      editMode: false,
    };
  }

  componentDidMount() {
    this.execSearch();
    LicenseActions.status();
  }

  execSearch = () => {
    const { dataAdapterId } = this.props;
    const { query, page, perPage } = this.state;
    MongoDBDataAdapterActions.search(dataAdapterId, query, page, perPage);
  };

  handleSearch = (query) => {
    this.setState({ query: query, page: 1 }, () => {
      this.execSearch();
    });
  };

  handleSearchReset = () => {
    this.setState({ query: '', page: 1 }, () => {
      this.execSearch();
    });
  };

  handlePageChange = (page, perPage) => {
    this.setState({ page: page, perPage: perPage }, () => {
      this.execSearch();
    });
  };

  handleInput = (key) => {
    return (event) => {
      const newState = {};
      newState[key] = trim(event.target.value);
      this.setState(newState);
    };
  };

  handleInputValues = (key) => {
    return (event) => {
      const newState = {};
      newState[key] = split(event.target.value, /\r?\n/);
      this.setState(newState);
    };
  };

  clearValues = () => {
    this.setState({ storeId: '', storeValues: [], storeKey: '', editMode: false, ttl: { value: 1, unit: '', enabled: false } });
  };

  clearValuesAndReloadEntries = () => {
    this.clearValues();
    this.execSearch();
  };

  // eslint-disable-next-line class-methods-use-this
  trimValues = (values: Array<string>) => {
    return uniq(values.map((value) => trim(value)).filter((value) => defaultTo(value, '').length > 0));
  };

  addEntry = () => {
    const { dataAdapterId } = this.props;
    const { storeId, storeKey, storeValues, ttl: { value: ttlValue, unit: ttlUnit, enabled: ttlEnabled }, editMode } = this.state;

    const newEntryBuilder = MongoDBDataAdapterEntry.builder()
      .dataAdapterId(dataAdapterId)
      .id(isEmpty(trim(storeId)) ? null : storeId)
      .key(storeKey)
      // Trim values here instead of the update handler to make sure users have a better editing experience
      .values(this.trimValues(storeValues))
      .ttl(ttlEnabled ? ttlValue : null)
      .ttl_unit(ttlEnabled ? ttlUnit : null);

    if (editMode) {
      MongoDBDataAdapterActions.update(newEntryBuilder.build())
        .then(this.clearValuesAndReloadEntries)
        .catch(this._errorHandler('Cannot update entry', 'Update failed'));
    } else {
      MongoDBDataAdapterActions.create(newEntryBuilder.build())
        .then(this.clearValuesAndReloadEntries)
        .catch(this._errorHandler('Cannot create entry', 'Creation failed'));
    }
  };

  // eslint-disable-next-line class-methods-use-this
  _errorHandler = (message, title) => {
    return (error) => {
      let errorMessage;

      try {
        if (isArray(error.additional.body)) {
          const invalidFields = error.additional.body.map((e) => last(split(e.path, '.'))).join(', ');
          errorMessage = `invalid fields: ${invalidFields}`;
        } else {
          errorMessage = error.additional.body.message;
        }
      } catch (e) {
        errorMessage = error.message;
      }

      UserNotification.error(`${message}: ${errorMessage}`, title);
    };
  };

  resetForm = () => {
    this.clearValues();
  };

  editEntry = (entry: MongoDBDataAdapterEntry) => {
    return () => {
      this.setState({ storeId: entry.id, storeKey: entry.key, storeValues: entry.values, ttl: { value: entry.ttl, unit: entry.ttlUnit, enabled: entry.ttl !== null }, editMode: true });
    };
  };

  removeEntry = (entry: MongoDBDataAdapterEntry) => {
    return () => {
      MongoDBDataAdapterActions.delete(entry).then(this.execSearch)
        .catch(this._errorHandler('Cannot delete entry', 'Deletion failed'));
    };
  };

  updateTTL = (value, unit, enabled) => {
    this._updateTTL(value, unit, enabled);
  };

  _updateTTL = (value, unit, enabled) => {
    const { ttl: ttlState } = this.state;
    const ttlObj = ObjectUtils.clone(ttlState);

    if (enabled && value) {
      ttlObj.value = value;
      ttlObj.enabled = enabled;
    } else {
      ttlObj.value = null;
      ttlObj.enabled = false;
    }

    ttlObj.unit = enabled ? unit : null;
    this.setState({ ttl: ttlObj });
  };

  render() {
    const { data, dataAdapterId } = this.props;
    const { pagination, entries = [] } = data;
    const entryList = entries.map((entry: MongoDBDataAdapterEntry) => {
      const key = entry.id;
      const values = entry.values || [];
      const expires = entry.expire_after || null;

      const valueList = slice(values, 0, 5).map((value) => {
        return <li key={btoa(value)}>&quot;{value}&quot;</li>;
      });

      if (values.length > 5) {
        valueList.push(<li key="truncated">[...] <em>(edit entry to see all values)</em></li>);
      }

      return (
        <tr key={key}>
          <td>{entry.key}</td>
          <td>
            <ol style={{ paddingInlineStart: '1.5em' }}>
              {valueList}
            </ol>
          </td>
          <td>{expires ? <Timestamp dateTime={expires} /> : 'Does not expire'}</td>
          <td style={{ width: '8.5em' }}>
            <ButtonToolbar>
              <Button id={`edit-entry-${key}`}
                      onClick={this.editEntry(entry)}
                      bsSize="xsmall"
                      bsStyle="info">
                Edit
              </Button>
              <Button id={`remove-entry-${key}`}
                      onClick={this.removeEntry(entry)}
                      bsSize="xsmall"
                      bsStyle="primary">
                Delete
              </Button>
            </ButtonToolbar>
          </td>
        </tr>
      );
    });

    const { storeValues, storeKey, editMode, ttl: { unit: ttlUnit, value: ttlValue, enabled: ttlEnabled } } = this.state;

    if (dataAdapterId === '' || !dataAdapterId) {
      return (
        <div>
          <h3>Entries can be added once the data adapter has been created.</h3>
        </div>
      );
    }

    return (
      <div>
        <LicenseCheck errorComponent={NoValidLicense}>
          <Row>
            <Col sm={12}>
              <h3 style={{ marginBottom: 10 }}>Create/update entries</h3>
              <Input type="text"
                     id="lookup-key"
                     label="Key"
                     value={storeKey}
                     placeholder="Enter key name here..."
                     help="The lookup key name"
                     labelClassName="col-sm-1"
                     wrapperClassName="col-sm-11"
                     onChange={this.handleInput('storeKey')} />
              <Input type="textarea"
                     id="lookup-key-values"
                     rows={5}
                     label="Values"
                     value={(storeValues || []).join('\n')}
                     placeholder="e.g. 127.0.0.1"
                     help="A list of values, one value per line."
                     labelClassName="col-sm-1"
                     wrapperClassName="col-sm-11"
                     onChange={this.handleInputValues('storeValues')} />
              <TimeUnitInput label="TTL"
                             update={this.updateTTL}
                             value={ttlValue}
                             help="Custom TTL for entries"
                             unit={ttlUnit || 'MINUTES'}
                             units={['MILLISECONDS', 'SECONDS', 'MINUTES', 'HOURS', 'DAYS']}
                             enabled={ttlEnabled}
                             labelClassName="col-sm-1"
                             wrapperClassName="col-sm-11" />
            </Col>
          </Row>
          <Row>
            <Col smOffset={1} sm={11}>
              <ButtonToolbar>
                <Button id="submit-entry"
                        disabled={isEmpty(this.trimValues(storeValues)) || storeKey === ''}
                        type="submit"
                        onClick={this.addEntry}
                        bsSize="small"
                        bsStyle="primary">
                  {editMode ? 'Update entry' : 'Add new entry'}
                </Button>
                <Button id="clear-entry"
                        onClick={this.resetForm}
                        bsSize="small">
                  Clear form
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </LicenseCheck>
        <Row>
          <Col sm={12}>
            <h3>Configured keys and values</h3>
            <PaginatedList onChange={this.handlePageChange}
                           activePage={pagination?.page}
                           totalItems={pagination?.total}
                           pageSize={pagination?.perPage}
                           pageSizes={[10, 50, 100]}
                           useQueryParameter={false}>
              <SearchForm onSearch={this.handleSearch}
                          onReset={this.handleSearchReset}
                          topMargin={10} />
              <Table striped condensed hover>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Values</th>
                    <th>Expires At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {entryList}
                </tbody>
              </Table>
            </PaginatedList>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(MongoDBDataAdapterTable, { adapter: MongoDBDataAdapterStore }, (props) => ({
  ...props,
  data: props.adapter.data || {},
}));
