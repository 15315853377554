import React, { createContext, useContext, useReducer } from 'react';

import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';

const SelectedInvestigationsContext = createContext(null);
const SelectedInvestigationsDispatchContext = createContext(null);

export type SelectedInvestigationsAction =
  | { type: 'update', payload: InvestigationAPIType }
  | { type: 'add', payload: InvestigationAPIType[] }
  | { type: 'remove', payload: InvestigationAPIType[] }
  | { type: 'clear' }

function selectedInvestigationsReducer(state: InvestigationAPIType[], action: SelectedInvestigationsAction) {
  switch (action.type) {
    case 'update':
      return state.map((sInv: InvestigationAPIType) => {
        if (sInv.id === action.payload.id) return action.payload;

        return sInv;
      });
    case 'add':
      return [
        ...state,
        ...action.payload.filter((pInv: InvestigationAPIType) => (
          !state.find((sInv: InvestigationAPIType) => sInv.id === pInv.id)
        )),
      ];
    case 'remove':
      return state.filter((sInv: InvestigationAPIType) => (
        !action.payload.find((pInv: InvestigationAPIType) => sInv.id === pInv.id)
      ));
    case 'clear':
      return [];
    default:
      return state;
  }
}

type ProviderProps = {
  children: React.ReactNode
};

export function SelectedInvestigationsProvider({ children }: ProviderProps) {
  const [selectedInvestigations, selectedInvestigationsDispatch] = useReducer(selectedInvestigationsReducer, []);

  return (
    <SelectedInvestigationsContext.Provider value={selectedInvestigations}>
      <SelectedInvestigationsDispatchContext.Provider value={selectedInvestigationsDispatch}>
        {children}
      </SelectedInvestigationsDispatchContext.Provider>
    </SelectedInvestigationsContext.Provider>
  );
}

export function useSelectedInvestigations() {
  return useContext(SelectedInvestigationsContext);
}

export function useSelectedInvestigationsDispatch() {
  return useContext(SelectedInvestigationsDispatchContext);
}
