import { useQuery } from '@tanstack/react-query';

import { LicensesActions } from 'license/LicensesStore';
import UserNotification from 'util/UserNotification';
import type { LicenseStatus } from 'license/types';

export const urlPrefix = '/plugins/org.graylog.plugins.license';
export const urlSuffix = 'licenses/status';
export const LICENSES_STATUS_KEY = 'licenses_status';

export type Result = {
  data: LicenseStatus,
  isLoading: boolean,
  refetch: () => unknown,
};

const useLicensesStatus = (): Result => {
  return useQuery([LICENSES_STATUS_KEY, {}], () => LicensesActions.listStatus(), {
    onError: (errorThrown) => {
      UserNotification.error(`Loading licenses status failed with: ${errorThrown}`);
    },
    retry: 0,
    keepPreviousData: true,
    notifyOnChangeProps: ['data', 'error'],
    initialData: {
      status: undefined,
    },
  });
};

export default useLicensesStatus;
