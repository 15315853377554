import React, { useEffect } from 'react';

import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import { useStore } from 'stores/connect';
import usePaginationQueryParameter from 'hooks/usePaginationQueryParameter';
import ForwarderDocs from 'forwarder/util/ForwarderDocs';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';

import InputProfilesListComponent from './input-profiles/InputProfilesListComponent';
import InputProfilesStore, { InputProfilesActions } from './stores/InputProfilesStore';
import ForwardersStore, { ForwardersActions } from './stores/ForwardersStore';
import EmptyInputProfileListComponent from './input-profiles/EmptyInputProfileListComponent';
import withIsPermitted from './util/withIsPermitted';
import ForwarderLicenseStatus from './ForwarderLicenseStatus';
import ForwarderLicenseStore, { ForwarderLicenseActions } from './stores/ForwarderLicenseStore';
import ForwarderPageNavigation from './ForwarderPageNavigation';

const REQUIRED_PERMISSIONS = ['forwarders:read', 'inputprofiles:read', 'forwarderinputs:read'];

const InputProfilesPage = () => {
  const { page, pageSize, resetPage } = usePaginationQueryParameter();
  const { inputProfiles, pagination } = useStore(InputProfilesStore);
  const { all: forwarders } = useStore(ForwardersStore);
  const licenseStatus = useStore(ForwarderLicenseStore);
  const sendTelemetry = useSendTelemetry();

  useEffect(() => {
    ForwarderLicenseActions.getLicenseStatus();
  }, []);

  useEffect(() => {
    InputProfilesActions.list({ page, pageSize });
    ForwardersActions.listAll();
  }, [page, pageSize]);

  const _reloadInputProfileList = () => {
    InputProfilesActions.list({
      page,
      pageSize,
      query: pagination.query,
    });
  };

  const _saveForwardersAssignment = (updatedForwarders) => {
    sendTelemetry('form_submit', {
      app_pathname: 'forwarder',
      app_section: 'input-profile',
      app_action_value: 'assign-forwarder',
    });

    const toBeUpdatedForwarders = [];

    updatedForwarders.forEach((uf) => {
      const forwarder = forwarders.find((f) => f.id === uf.forwarderId);

      if (forwarder) {
        toBeUpdatedForwarders.push({ ...forwarder, input_profile_id: uf.inputProfileId });
      }
    });

    ForwardersActions.updates(toBeUpdatedForwarders);
  };

  const isLoading = () => {
    return !inputProfiles || !forwarders;
  };

  const handleQueryChange = (newPage?: number, newPageSize?: number, query?: string) => {
    resetPage();

    InputProfilesActions.list({
      page: newPage,
      pageSize: newPageSize,
      query,
    });
  };

  const loadContent = () => {
    return inputProfiles.length === 0 && pagination.query === '' ? <EmptyInputProfileListComponent />
      : (
        <InputProfilesListComponent inputProfiles={inputProfiles}
                                    forwarders={forwarders}
                                    onForwardersAssign={_saveForwardersAssignment}
                                    onInputProfileUpdate={_reloadInputProfileList}
                                    pagination={pagination}
                                    onQueryChange={handleQueryChange} />
      );
  };

  return (
    <DocumentTitle title="Input Profiles">
      <ForwarderPageNavigation />
      <PageHeader title="Input Profiles"
                  documentationLink={{
                    title: 'Input profile documentation',
                    path: ForwarderDocs.INPUT_PROFILE,
                  }}>
        <span>List of Input Profiles created in this Graylog instance.</span>
      </PageHeader>
      <ForwarderLicenseStatus licenseStatus={licenseStatus} />
      {isLoading() ? <Spinner /> : loadContent()}
    </DocumentTitle>
  );
};

export default withIsPermitted(InputProfilesPage, REQUIRED_PERMISSIONS);
