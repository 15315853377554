import * as React from 'react';
import styled from 'styled-components';

import { DocumentTitle, PageHeader } from 'components/common';
import { Alert } from 'components/bootstrap';
import PageHeaderWrapper from 'security-app/components/common/PageHeaderWrapper';

const StyledH4 = styled.h4`
  font-weight: bold;
  margin-bottom: 5px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 15px;
`;

const OpenSearchRequiredPage = () => {
  return (
    <DocumentTitle title="Unsupported backend search version">
      <PageHeaderWrapper>
        <PageHeader title="Unsupported backend search version">
          <StyledAlert bsStyle="danger" className="tm">
            <StyledH4>Anomaly Detection is disabled</StyledH4>
            <p>
              Anomaly Detection is disabled because the Graylog server is not using OpenSearch.
            </p>
          </StyledAlert>
        </PageHeader>
      </PageHeaderWrapper>
    </DocumentTitle>
  );
};

export default OpenSearchRequiredPage;
