import * as React from 'react';
import styled from 'styled-components';

import { Backdrop, BrandDropdown } from 'security-app/components/common/BrandedDropdown';

const MenuContainer = styled.div`
  position: relative;
`;

const Trigger = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 100%;
`;

type Props = {
  trigger: React.ReactNode,
  posX?: 'left' | 'right',
  posY?: 'top' | 'bottom',
  children: React.ReactNode,
  onClose?: () => void,
};

const DropdownMenu = ({ trigger, posX, posY, children, onClose }: Props) => {
  const [open, setOpen] = React.useState(false);

  const onClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const auxOpen = !open;
    if (!auxOpen) onClose();
    setOpen(auxOpen);
  };

  const childrenWithProps = React.Children.map(children, (child: React.ReactNode) => {
    return React.cloneElement(child as React.ReactElement<any>, { closeMenu: onClick });
  });

  return (
    <MenuContainer>
      <Trigger onClick={onClick}>{trigger}</Trigger>
      <BrandDropdown show={open}
                     $posx={posX}
                     $posy={posY}
                     onClick={(e: React.BaseSyntheticEvent) => e.stopPropagation()}>
        {childrenWithProps}
      </BrandDropdown>
      <Backdrop data-testid="backdrop" $show={open} onClick={onClick} />
    </MenuContainer>
  );
};

DropdownMenu.defaultProps = {
  posX: 'right',
  posY: 'bottom',
  onClose: () => {},
};

export default DropdownMenu;
