import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { defaultCompare as naturalSort } from 'logic/DefaultCompare';
import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';
import { ButtonGroup, Button, ControlLabel, FormGroup, HelpBlock, Input, Alert } from 'components/bootstrap';
import { Icon } from 'components/common';
import { TIME_BASED_SIZE_OPTIMIZING_ROTATION_STRATEGY } from 'stores/indices/IndicesStore';

const StyledAlert = styled(Alert)`
  overflow: auto;
  margin-right: 15px;
  margin-left: 15px;
`;

type Props = {
  config: {
    max_number_of_indices: number,
    index_action: string,
  },
  jsonSchema: any,
  updateConfig: Function,
};

const ArchiveRetentionStrategyConfiguration = ({ config, jsonSchema, updateConfig }: Props) => {
  const [configuration, setConfiguration] = useState(config);
  const {
    values: {
      rotation_strategy_class: rotationStrategyClass,
    },
  } = useFormikContext<any>();

  const onInputUpdate = (field) => {
    return (e) => {
      const update = configuration;
      update[field] = e.target.value;
      setConfiguration(update);
      updateConfig(update);
    };
  };

  const setIndexAction = (type: string) => {
    return () => {
      setConfiguration({ ...configuration, index_action: type });
      updateConfig({ ...configuration, index_action: type });
    };
  };

  const indexActionActive = (type) => {
    const { index_action } = configuration;

    return index_action === type;
  };

  const hasValidSchema = () => {
    return !!jsonSchema;
  };

  const indexActions = () => {
    if (hasValidSchema()) {
      const newIndexActions = jsonSchema.properties.index_action.enum;
      newIndexActions.sort((a, b) => naturalSort(a.toLowerCase(), b.toLowerCase()));

      return newIndexActions;
    }

    return [];
  };

  // Get all available index actions from the JSON schema.
  const buttons = indexActions()?.map((action) => {
    const isActive = indexActionActive(action);

    return (
      <Button key={`button-${action}`}
              onClick={setIndexAction(action)}
              active={isActive}
              bsStyle={isActive ? 'info' : 'default'}>
        {action}
      </Button>
    );
  });
  const { max_number_of_indices } = configuration;

  return (
    <div>
      <ArchiveLicenseStatusContainer summary />
      <StyledAlert>
        <Icon name="info-circle" />{' '} The archive index retention strategy creates an archive for an index before deleting or closing it.
      </StyledAlert>
      {rotationStrategyClass !== TIME_BASED_SIZE_OPTIMIZING_ROTATION_STRATEGY && (
      <fieldset>
        <Input type="number"
               id="max-number-of-indices"
               label="Max number of indices"
               labelClassName="col-sm-3"
               wrapperClassName="col-sm-9"
               onChange={onInputUpdate('max_number_of_indices')}
               value={max_number_of_indices}
               help={<span>Maximum number of indices to keep before <strong>archiving</strong> the oldest ones</span>}
               autoFocus
               required />
      </fieldset>
      )}
      {hasValidSchema() && (
        <FormGroup id="action-after-archive">
          <ControlLabel className="col-sm-3">Action after archiving index</ControlLabel>
          <div className="col-sm-9">
            <ButtonGroup bsSize="small">{buttons}</ButtonGroup>
            <HelpBlock>Please select which action should be performed once an index has been archived.</HelpBlock>
          </div>
        </FormGroup>
      )}
    </div>
  );
};

ArchiveRetentionStrategyConfiguration.propTypes = {
  config: PropTypes.object.isRequired,
  jsonSchema: PropTypes.any,
  updateConfig: PropTypes.func.isRequired,
};

ArchiveRetentionStrategyConfiguration.defaultProps = {
  jsonSchema: undefined,
};

export default ArchiveRetentionStrategyConfiguration;
