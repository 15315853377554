import * as React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import StringUtils from 'util/StringUtils';
import { Select, InputDescription } from 'components/common';

type Props = {
  id: string,
  required?: boolean,
}

const DayOfWeekSelect = ({ id, required }: Props) => {
  const dow = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => {
    return { value: day, label: StringUtils.capitalizeFirstLetter(day) };
  });

  return (
    <Field name={id}>
      {({ field: { name, value, onChange, onBlur }, meta: { error, touched } }) => (
        <>
          <Select onBlur={onBlur}
                  onChange={(newValue) => onChange({
                    target: { value: newValue, name },
                  })}
                  options={dow}
                  inputId={name}
                  placeholder="Select day of the week"
                  required={required}
                  value={value ? StringUtils.stringify(value) : ''} />
          {(error && touched) && <InputDescription error={error} className="no-bm" />}
        </>
      )}
    </Field>
  );
};

DayOfWeekSelect.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

DayOfWeekSelect.defaultProps = {
  required: false,
};

export default DayOfWeekSelect;
