import * as React from 'react';
import styled from 'styled-components';

import Routes from 'routing/Routes';
import { Header, NavRow, NavTabLink, SectionHeading } from 'security-app/components/Layout';
import useSearchVersionCheck from 'hooks/useSearchVersionCheck';
import { Spinner } from 'components/common';
import OpenSearchRequiredPage from 'security-app/pages/OpenSearchRequiredPage';
import StyledPageContentLayout from 'security-app/components/common/StyledPageContentLayout';

const Container = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;
`;

type AnomalyLayoutProps = {
  children: React.ReactNode;
};

export default function AnomalyLayout({ children }: AnomalyLayoutProps) {
  const { data: usingOpenSearch, isLoading, error } = useSearchVersionCheck('opensearch');
  if (isLoading) return <Spinner />;
  if (error) return <div>Error: {error.message}</div>;

  if (!usingOpenSearch.satisfied) {
    return (
      <StyledPageContentLayout>
        <OpenSearchRequiredPage />
      </StyledPageContentLayout>
    );
  }

  return (
    <Container>
      <Header>
        <SectionHeading>Anomalies</SectionHeading>
        <NavRow>
          <NavTabLink to={Routes.pluginRoute('SECURITY_ANOMALIES')} end>Dashboard</NavTabLink>
          <NavTabLink to={Routes.pluginRoute('SECURITY_ANOMALIES_DETECTORS')}>Anomaly Detectors</NavTabLink>
        </NavRow>
      </Header>
      <div>{children}</div>
    </Container>
  );
}
