import * as React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components';

import { Link } from 'components/common/router';
import { isPermitted } from 'util/PermissionsMixin';
import { DataTable, NoSearchResult, PaginatedList, QueryHelper, SearchForm } from 'components/common';
import type { SearchFilterUsageEntity } from 'search-filter/types';
import getShowRouteForEntity from 'routing/getShowRouteForEntity';
import HoverForHelp from 'components/common/HoverForHelp';
import useCurrentUser from 'hooks/useCurrentUser';
import useSearchFilterUsagesQuery from 'search-filter/hooks/useSearchFilterUsagesQuery';

const TABLE_HEADERS = ['Search Name', 'Owner (Creator)', 'Capability'];
export const DEFAULT_PAGINATION = {
  per_page: 25,
  page: 1,
  count: 0,
  total: 0,
};
const DEFAULT_QUERY = {
  query: '',
  per_page: 10,
  page: 1,
  sort: 'title',
  order: 'asc',
};

const StyledPaginatedList = styled(PaginatedList)`
  .pagination {
    margin: 0;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHoverForHelp = styled(HoverForHelp)`
  margin-left: 5px;
`;

const FilterUsagesInSearchesItem = ({ id, type, title, owner, capability }: SearchFilterUsageEntity) => {
  const currentUser = useCurrentUser();
  const hasViewPermissions = isPermitted(currentUser.permissions, `view:read:${id}`);

  return (
    <tr key={id}>
      <td className="limited">
        <Title>
          {hasViewPermissions ? <Link to={getShowRouteForEntity(id, type)}>{title}</Link> : title}
          {capability === 'None' && (
            <StyledHoverForHelp title="Missing Permissions">
              This {type.toLowerCase()} is including the search filter, but you do not have any permissions for it.
            </StyledHoverForHelp>
          )}
        </Title>
      </td>
      <td>{owner}</td>
      <td>{capability}</td>
    </tr>
  );
};

type Props = {
  filterId: string
};

const FilterUsagesInSearches = ({ filterId }: Props) => {
  const [filterQuery, setSearchFilter] = useState(DEFAULT_QUERY);
  const { data } = useSearchFilterUsagesQuery(filterId, filterQuery);

  const handlePaginationChange = useCallback((page: number) => {
    setSearchFilter((cur) => ({ ...cur, page }));
  }, [setSearchFilter]);

  const handleSearch = useCallback((newSearchQuery: string | undefined) => setSearchFilter((cur) => ({
    ...cur,
    page: DEFAULT_QUERY.page,
    query: newSearchQuery,
  })), []);
  const handleReset = useCallback(() => handleSearch(DEFAULT_QUERY.query), [handleSearch]);
  const renderOverviewItem = useCallback((entity: SearchFilterUsageEntity) => <FilterUsagesInSearchesItem {...entity} />, []);

  return (
    <>
      <p className="description">
        Found {data.pagination.total} saved search which include this search filter.
      </p>

      <StyledPaginatedList activePage={filterQuery.page}
                           totalItems={data.pagination.total}
                           onChange={handlePaginationChange}>
        <DataTable className="table-hover"
                   customFilter={(
                     <SearchForm onSearch={handleSearch}
                                 queryHelpComponent={(
                                   <QueryHelper entityName="search"
                                                commonFields={['title']} />
                                 )}
                                 onReset={handleReset}
                                 topMargin={0} />
                   )}
                   dataRowFormatter={renderOverviewItem}
                   noDataText={<NoSearchResult>No usages have been found.</NoSearchResult>}
                   headers={TABLE_HEADERS}
                   id="shared-entities"
                   rowClassName="no-bm"
                   rows={data.elements}
                   sortByKey="title" />
      </StyledPaginatedList>
    </>
  );
};

export default FilterUsagesInSearches;
