import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { LinkContainer, Link } from 'components/common/router';
import { IfPermitted } from 'components/common';
import { DropdownButton, MenuItem, BootstrapModalConfirm } from 'components/bootstrap';
import Routes from 'routing/Routes';

import InputProfileAssignForm from './InputProfileAssignForm';
import EditInputProfileForm from './EditInputProfileForm';

import type { InputProfile, Forwarder } from '../Types';
import { InputProfilePropType, ForwarderPropType } from '../Types';
import { InputProfilesActions } from '../stores/InputProfilesStore';

type Props = {
  inputProfile: InputProfile,
  forwarderCount: number,
  forwarders: Array<Forwarder>,
  onForwardersAssign: (updatedForwarders: Array<{forwarderId: string, inputProfileId: string | null}>) => void,
  onInputProfileUpdate: (inputProfile: InputProfile) => void,
};

const InputProfileEntry = ({ inputProfile, forwarderCount, forwarders, onForwardersAssign, onInputProfileUpdate }: Props) => {
  const { id, title, description } = inputProfile;
  const [showModal, setShowModal] = useState<boolean>(false);
  const _openConfirmation = () => setShowModal(true);
  const _closeConfirmation = () => setShowModal(false);

  const assignedForwarders = useMemo(() => {
    return forwarders.filter((f) => f.input_profile_id === inputProfile.id);
  }, [forwarders, inputProfile]);

  const _onDelete = () => {
    InputProfilesActions.delete(id);
  };

  return (
    <tr>
      <td>
        <Link to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES_INPUTPROFILEID')(id)}>
          {title}
        </Link>
      </td>
      <td>{description}</td>
      <td>{forwarderCount}</td>
      <td>
        <IfPermitted permissions="inputprofiles:edit">
          <EditInputProfileForm inputProfile={inputProfile} onSave={onInputProfileUpdate} buttonBsStyle="primary" buttonBsSize="xsmall" />
        </IfPermitted>
          &nbsp;
        <span key="split-button-default">
          <DropdownButton bsSize="xsmall"
                          id="input-profile-action"
                          title="Choose action"
                          bsStyle="default"
                          pullRight>
            <LinkContainer to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES_INPUTPROFILEID')(id)}>
              <MenuItem eventKey="1">View</MenuItem>
            </LinkContainer>
            <IfPermitted permissions="forwarders:edit">
              <InputProfileAssignForm isButton={false}
                                      inputProfileId={id}
                                      assignedForwarders={assignedForwarders}
                                      forwarders={forwarders}
                                      onForwardersAssign={onForwardersAssign} />
            </IfPermitted>
            <IfPermitted permissions="inputprofiles:delete">
              {forwarderCount > 0 ? (
                <MenuItem eventKey="3" disabled>Delete disabled: still in use</MenuItem>
              ) : (
                <MenuItem eventKey="3" onClick={_openConfirmation}>Delete</MenuItem>
              )}
            </IfPermitted>
          </DropdownButton>
        </span>
        <BootstrapModalConfirm showModal={showModal}
                               title="Delete Input profile"
                               onConfirm={_onDelete}
                               onCancel={_closeConfirmation}>
          {`Are you sure you want to delete Input Profile "${title}"? This will delete all 
            inputs in this input profile.`}
        </BootstrapModalConfirm>
      </td>
    </tr>
  );
};

InputProfileEntry.propTypes = {
  inputProfile: InputProfilePropType,
  forwarders: PropTypes.arrayOf(ForwarderPropType).isRequired,
  forwarderCount: PropTypes.number.isRequired,
  onForwardersAssign: PropTypes.func.isRequired,
  onInputProfileUpdate: PropTypes.func.isRequired,
};

InputProfileEntry.defaultProps = {
  inputProfile: {},
};

export default InputProfileEntry;
