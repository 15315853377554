import * as React from 'react';
import styled from 'styled-components';
import type { DefaulTheme } from 'styled-components';

import { useGetLatestBundle } from 'illuminate/hooks/useBundlesAPI';

const Badge = styled.span`
  position: relative;

  &::after {
    display: 'block';
    content: ' ';
    position: absolute;
    width: 8px;
    height: 8px;

    background-color: ${({ theme }: { theme: DefaulTheme }) => theme.colors.brand.primary};
    border-radius: 50%;
    top: -4px;
    right: -10px;
  }
`;

function IlluminateDropdownBadge({ text }: { text: string }) {
  const { version, isNew } = useGetLatestBundle();

  return isNew ? (
    <Badge title={`New Illuminate bundle version ${version}`}>{text}</Badge>
  ) : <span>{text}</span>;
}

export default IlluminateDropdownBadge;
