import * as React from 'react';

import useCurrentUser from 'hooks/useCurrentUser';
import BootstrapModalConfirm from 'components/bootstrap/BootstrapModalConfirm';
import { Input, Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import Routes from 'routing/Routes';
import { useAddEvidence, useRemoveEvidence } from 'security-app/hooks/useInvestigationsAPI';
import type { InvestigationsDetailsAPIType, LogEvidenceType } from 'security-app/hooks/api/investigationsAPI.types';
import useHistory from 'routing/useHistory';

import {
  Row,
  FormGroup,
  EvidenceList,
  EvidenceRow,
  IconButton,
  StyledLink,
} from '../styled.components';

type Props = {
  investigation: InvestigationsDetailsAPIType,
};

function Logs({ investigation }: Props) {
  const { permissions } = useCurrentUser();
  const [newLogId, setNewLogId] = React.useState<string>('');
  const [newLogIdx, setNewLogIdx] = React.useState<string>('');
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
  const [deleteLog, setDeleteLog] = React.useState<LogEvidenceType>(null);
  const history = useHistory();

  const canManageEvidence = React.useMemo(() => {
    return !investigation.archived
      && (permissions.includes('investigations:edit') || permissions.includes('*'));
  }, [investigation.archived, permissions]);

  const { addEvidence } = useAddEvidence();
  const { removeEvidence } = useRemoveEvidence();

  const isFormValid = React.useMemo(() => (
    newLogId && newLogId.replace(/\s/g, '').length > 0
    && newLogIdx && newLogIdx.replace(/\s/g, '').length > 0
  ), [newLogId, newLogIdx]);

  const onAddLog = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();

    if (isFormValid) {
      await addEvidence({
        invId: investigation.id,
        payload: {
          logs: [{ id: newLogId, index: newLogIdx }],
        },
      });
    }

    setNewLogId('');
    setNewLogIdx('');
  };

  const removeLog = async () => {
    await removeEvidence({
      invId: investigation.id,
      payload: {
        logs: [{ id: deleteLog.id, index: deleteLog.index }],
      },
    });

    setShowDeleteDialog(false);
    setDeleteLog(null);
  };

  const onDelete = (log: LogEvidenceType) => (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
    setDeleteLog(log);
  };

  const onCancelDelete = () => {
    setShowDeleteDialog(false);
    setDeleteLog(null);
  };

  return (
    <>
      {canManageEvidence && (
        <form onSubmit={onAddLog}>
          <Row>
            <FormGroup>
              <Input id="add-log-id"
                     type="text"
                     label="Add Log"
                     data-testid="add-log-id"
                     autoComplete="off"
                     placeholder="Enter Log ID"
                     value={newLogId}
                     onChange={(e: React.BaseSyntheticEvent) => setNewLogId(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Input id="add-log-idx"
                     type="text"
                     data-testid="add-log-idx"
                     autoComplete="off"
                     placeholder="Enter Log Index"
                     value={newLogIdx}
                     onChange={(e: React.BaseSyntheticEvent) => setNewLogIdx(e.target.value)} />
            </FormGroup>
            <Button type="submit"
                    bsStyle="success"
                    onClick={onAddLog}
                    disabled={!isFormValid}>
              Add
            </Button>
          </Row>
        </form>
      )}
      <EvidenceList>
        {investigation.logs.length === 0
          ? <div style={{ color: 'gray' }}>There are no logs to list</div>
          : investigation.logs.map((log: LogEvidenceType) => (
            <EvidenceRow key={log.id} $fullWidth $gap="0">
              {canManageEvidence && (
                <IconButton $danger
                            data-testid={`delete-${log.id}`}
                            onClick={onDelete(log)}>
                  <Icon name="times" />
                </IconButton>
              )}
              <StyledLink onClick={() => history.push(Routes.message_show(log.index, log.id))}>
                {log.id} <span style={{ color: 'gray' }}>({log.index})</span>
              </StyledLink>
            </EvidenceRow>
          ))}
      </EvidenceList>
      <BootstrapModalConfirm showModal={showDeleteDialog}
                             title="Delete Log"
                             onConfirm={removeLog}
                             onCancel={onCancelDelete}>
        Are you sure you want to delete this Log?
      </BootstrapModalConfirm>
    </>
  );
}

export default Logs;
