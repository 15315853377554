import * as React from 'react';
import styled from 'styled-components';

import ErrorBoundary from 'report/common/ErrorBoundary';
import ReportingFallbackWidget from 'report/common/ReportingFallbackWidget';
import ReportingWidget from 'report/common/ReportingWidget';
import { RenderCompletionObserver } from 'components/visualizations';
import { widgetDefinition } from 'views/logic/Widgets';
import type { Report, ReportingWidgetResult } from 'report/types';

const WIDGET_ASPECT_RATIO = 3 / 4;
const WIDGET_WIDTH = 600;

const Visualization = styled.div`
  margin-top: 10px;
  margin-bottom: 30px
`;

type Props = {
  widgets: Report['widgets'],
  positions: Report['positions'],
  onWidgetRenderComplete: (widgetId: string) => () => void,
  hideWidgetDescription: boolean,
  hideWidgetQuery: boolean,
  results: Array<ReportingWidgetResult>,
};

const ReportWidgets = ({
  widgets,
  positions,
  onWidgetRenderComplete,
  hideWidgetDescription,
  hideWidgetQuery,
  results,
}: Props) => {
  return (
    <>
      {widgets.sort((widgetA, widgetB) => {
        if (positions.length === 0) {
          return 0;
        }

        const positionA = positions.find((p) => p.dashboard_widget_id === widgetA.dashboard_widget_id);
        const positionB = positions.find((p) => p.dashboard_widget_id === widgetB.dashboard_widget_id);

        return (positionA && positionB ? positionA.row - positionB.row : 0);
      })
        .map((widget) => {
          const value = results.find((result) => result.dashboard_id === widget.dashboard_id && result.widget_id === widget.dashboard_widget_id)?.result;
          const widgetDef = widgetDefinition(widget.type);
          const reportStyle = typeof widgetDef.reportStyle === 'function' ? widgetDef.reportStyle() : { width: WIDGET_WIDTH };
          const widgetWidth = Number(reportStyle.width);
          // Width is going to be limited by the paper size, but we try our best to adjust the visualization height.
          const effectiveHeight = widgetWidth * WIDGET_ASPECT_RATIO;
          const _handleRenderComplete = onWidgetRenderComplete(widget.dashboard_widget_id);
          const renderFallback = ({ error }) => <ReportingFallbackWidget widget={widget} error={error} />;

          return (
            <Visualization key={widget.dashboard_widget_id}>
              <RenderCompletionObserver onRenderComplete={_handleRenderComplete}>
                <ErrorBoundary FallbackComponent={renderFallback}
                               onDidCatch={_handleRenderComplete}>
                  <ReportingWidget widget={widget}
                                   value={value}
                                   width={widgetWidth}
                                   height={effectiveHeight}
                                   showHandle={false}
                                   interactive={false}
                                   hideDescription={hideWidgetDescription}
                                   hideQuery={hideWidgetQuery} />
                </ErrorBoundary>
              </RenderCompletionObserver>
            </Visualization>
          );
        })}
    </>
  );
};

export default ReportWidgets;
