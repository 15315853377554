import * as React from 'react';
import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import moment from 'moment';

import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useCurrentUser from 'hooks/useCurrentUser';
import BootstrapModalConfirm from 'components/bootstrap/BootstrapModalConfirm';
import { Input, Button } from 'components/bootstrap';
import type { InvestigationsDetailsAPIType, NoteType } from 'security-app/hooks/api/investigationsAPI.types';
import { useAddNote, useEditNote, useDeleteNote } from 'security-app/hooks/useInvestigationsAPI';

import {
  Container,
  Row,
  FormGroup,
} from './styled.components';

const NewNoteForm = styled.div(({ theme }: { theme: DefaultTheme }) => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  position: sticky;
  top: -16px;
  background-color: ${theme.colors.global.background};
  margin: -16px -16px;
  padding: 16px;

  z-index: 99;
`);

const NoteHeaderRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 5px;
  font-weight: bold;

  & .note-meta {
    font-weight: normal;
    color: gray;
  }
`;

const NoteButton = styled(Button)`
  height: 22px;
  border-width: 1px !important;
  line-height: 1;
  font-size: 0.75rem;
`;

const NoteContent = styled(FormGroup)(({ theme }: { theme: DefaultTheme }) => css`
  textarea[readonly] {
    background-color: ${theme.colors.global.contentBackground} !important;
    color: ${theme.colors.global.textDefault} !important;
  }

  .action-button {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover .action-button {
    opacity: 1;
  }
`);

type Props = {
  investigation: InvestigationsDetailsAPIType,
};

const NewNoteHeader = ({ newNote, onAddNote }: { newNote: string, onAddNote: (text: string) => void }) => {
  return (
    <NoteHeaderRow $fullWidth>
      <span>Add Note</span>
      <NoteButton bsStyle="success" bsSize="small" onClick={onAddNote} disabled={!newNote}>
        Add
      </NoteButton>
    </NoteHeaderRow>
  );
};

type NoteHeaderProps = {
  note: NoteType,
  editing: boolean,
  canManageNotes: boolean,
  onAction: (action: string) => void,
};

const NoteHeader = ({ note, editing, canManageNotes, onAction }: NoteHeaderProps) => {
  return (
    <NoteHeaderRow $fullWidth>
      <span className="note-meta">{note.created_by} - {moment(note.created_at).format('MM/DD/YYYY')}</span>
      {canManageNotes && (
        <Row $gap="0.4rem">
          {editing ? (
            <>
              <NoteButton bsStyle="success"
                          bsSize="small"
                          onClick={() => onAction('save')}>
                Save
              </NoteButton>
              <NoteButton bsSize="small" onClick={() => onAction('cancel')}>
                Cancel
              </NoteButton>
            </>
          ) : (
            <>
              <NoteButton className="action-button"
                          bsStyle="primary"
                          bsSize="small"
                          onClick={() => onAction('edit')}>
                Edit
              </NoteButton>
              <NoteButton className="action-button"
                          bsStyle="danger"
                          bsSize="small"
                          onClick={() => onAction('delete')}>
                Delete
              </NoteButton>
            </>
          )}
        </Row>
      )}
    </NoteHeaderRow>
  );
};

function Notes({ investigation }: Props) {
  const { permissions } = useCurrentUser();
  const sendTelemetry = useSendTelemetry();
  const [newNote, setNewNote] = React.useState<string>('');
  const [editNoteContent, setEditNoteContent] = React.useState<string>('');
  const [editNoteId, setEditNoteId] = React.useState<string>(null);
  const [deleteNoteId, setDeleteNoteId] = React.useState<string>(null);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);

  const canManageNotes = React.useMemo(() => {
    return !investigation.archived
      && (permissions.includes('investigations:edit') || permissions.includes('*'));
  }, [investigation.archived, permissions]);

  const { addNote } = useAddNote();
  const { editNote } = useEditNote();
  const { deleteNote } = useDeleteNote();

  const onAddNote = async () => {
    sendTelemetry('click', {
      app_pathname: 'security',
      app_section: 'investigation',
      app_action_value: 'note-add',
    });

    await addNote({ invId: investigation.id, note: newNote });

    setNewNote('');
  };

  const saveNoteChanges = async (noteId: string) => {
    sendTelemetry('form_submit', {
      app_pathname: 'security',
      app_section: 'investigation',
      app_action_value: 'note-save',
    });

    await editNote({ invId: investigation.id, noteId: noteId, note: editNoteContent });
    setEditNoteId(null);
    setEditNoteContent('');
  };

  const onDeleteNote = async () => {
    await deleteNote({ invId: investigation.id, noteId: deleteNoteId });
    setShowDeleteDialog(false);
    setDeleteNoteId(null);
  };

  const onCancelDelete = () => {
    setShowDeleteDialog(false);
    setDeleteNoteId(null);
  };

  const handleNoteAction = (note: NoteType) => (action: string) => {
    sendTelemetry('click', {
      app_pathname: 'security',
      app_section: 'investigation',
      app_action_value: `note-${action}`,
    });

    switch (action) {
      case 'edit':
        setEditNoteId(note.id);
        setEditNoteContent(note.content);
        document.getElementById(`note-${note.id}`).focus();
        break;
      case 'cancel':
        setEditNoteId(null);
        setEditNoteContent('');
        break;
      case 'save':
        saveNoteChanges(note.id);
        break;
      case 'delete':
        setShowDeleteDialog(true);
        setDeleteNoteId(note.id);
        break;
      default:
        break;
    }
  };

  const isEditing = React.useCallback((noteId: string) => {
    return noteId === editNoteId;
  }, [editNoteId]);

  return (
    <Container>

      <NewNoteForm>
        <div>Notes:</div>
        {canManageNotes && (
          <Row>
            <FormGroup>
              <NewNoteHeader newNote={newNote} onAddNote={onAddNote} />
              <Input id="investigation-new-note-id"
                     type="textarea"
                     data-testid="new-note"
                     rows="3"
                     autoComplete="off"
                     placeholder="Enter a note"
                     value={newNote}
                     onChange={(e: React.BaseSyntheticEvent) => setNewNote(e.target.value)}
                     readOnly={investigation.archived} />
            </FormGroup>
          </Row>
        )}
      </NewNoteForm>
      {investigation.notes.length === 0
        ? <div style={{ color: 'gray' }}>There are no notes to list</div>
        : investigation.notes.map((note: NoteType) => (
          <Row key={note.id}>
            <NoteContent>
              <NoteHeader note={note}
                          editing={isEditing(note.id)}
                          canManageNotes={canManageNotes}
                          onAction={handleNoteAction(note)} />
              <Input id={`note-${note.id}`}
                     type="textarea"
                     rows="3"
                     data-testid={`note-${note.id}`}
                     autoFocus={isEditing(note.id)}
                     readOnly={!isEditing(note.id) || investigation.archived}
                     value={isEditing(note.id) ? editNoteContent : note.content}
                     onChange={(e: React.BaseSyntheticEvent) => setEditNoteContent(e.target.value)} />
            </NoteContent>
          </Row>
        ))}
      <BootstrapModalConfirm showModal={showDeleteDialog}
                             title="Delete Note"
                             onConfirm={onDeleteNote}
                             onCancel={onCancelDelete}>
        Are you sure you want to delete this note?
      </BootstrapModalConfirm>
    </Container>
  );
}

export default Notes;
