import * as React from 'react';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import type { UserSearchFilter } from 'search-filter/types';
import UserSearchFilterForm from 'search-filter/components/filter-management-pages/UserSearchFilterForm';
import { Col, Row, Alert } from 'components/bootstrap';
import { Headline } from 'components/common/Section/SectionComponent';
import Routes from 'routing/Routes';
import useUserSearchFilterQuery from 'search-filter/hooks/useUserSearchFilterQuery';
import useUserSearchFilterMutation from 'search-filter/hooks/useUserSearchFilterMutation';
import { NoSearchResult, Spinner } from 'components/common';
import useSearchFilterUsagesSummaryQuery from 'search-filter/hooks/useSearchFilterUsagesSummaryQuery';
import readableFilterUsagesSummary from 'search-filter/readableFilterUsagesSummary';
import { Link } from 'components/common/router';
import StringUtils from 'util/StringUtils';
import confirmActionUsagesAlert from 'search-filter/confirmActionUsagesAlert';
import useHistory from 'routing/useHistory';

const UserSearchFilterEdit = () => {
  const { filterId } = useParams();
  const { put } = useUserSearchFilterMutation();
  const { data: searchFilter, isFetching } = useUserSearchFilterQuery(filterId);
  const { data: { summary: usagesSummary }, isFetching: isFetchingUsagesSummary } = useSearchFilterUsagesSummaryQuery(filterId);
  const history = useHistory();

  const onSave = useCallback((updatedSearchFilter: UserSearchFilter) => {
    const queryHasChanged = searchFilter.queryString !== updatedSearchFilter.queryString;

    // eslint-disable-next-line no-alert
    if (usagesSummary?.length && queryHasChanged && !confirmActionUsagesAlert({ action: 'Changing', usagesSummary })) {
      return Promise.resolve();
    }

    return put({ id: filterId, data: updatedSearchFilter }).then(() => {
      history.push(Routes.pluginRoute('MY-FILTERS'));
    });
  }, [filterId, history, put, searchFilter?.queryString, usagesSummary]);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <Row className="content">
      <Col lg={8}>
        <Row>
          <Col xs={12}>
            <Headline>Edit Filter</Headline>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <UserSearchFilterForm onSubmit={onSave}
                                  disableSubmitButton={isFetchingUsagesSummary}
                                  submitButtonText="Update filter"
                                  submitLoadingText="Updating filter..."
                                  initialValues={{
                                    queryString: searchFilter?.queryString,
                                    title: searchFilter?.title,
                                    description: searchFilter?.description,
                                  }}>
              <Row className="has-bm">
                <Col md={9} mdOffset={3}>
                  {isFetchingUsagesSummary && <Spinner text="Loading usages summary..." />}
                  {!isFetchingUsagesSummary && !usagesSummary?.length && (
                    <NoSearchResult>
                      Editing this search filter will not affect any saved search or dashboard, since the filter is
                      currently not being referenced.
                    </NoSearchResult>
                  )}
                  {!isFetchingUsagesSummary && !!usagesSummary?.length && (
                    <Alert className="no-bm" bsStyle="danger">
                      <b>Attention!</b><br />
                      This search filter is being referenced by <Link to={Routes.pluginRoute('MY-FILTERS_DETAILS_FILTERID')(filterId)} target="_blank">{readableFilterUsagesSummary(usagesSummary)}</Link>.
                      Changing the search filter query will affect {StringUtils.pluralize(usagesSummary.length, 'its', 'their')} search result.
                    </Alert>
                  )}
                </Col>
              </Row>
            </UserSearchFilterForm>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserSearchFilterEdit;
