import * as React from 'react';

import type View from 'views/logic/views/View';
import { MenuItem } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import useLicenseCheck from 'license/useLicenseCheck';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';

import AddEvidence from './CoreAddEvidence';
import AddEvidenceModal from './shared/AddEvidenceModal';

const addToInvestigation = ({ investigationSelected }) => (
  <MenuItem disabled={!investigationSelected} icon="puzzle-piece">
    Add to investigation
  </MenuItem>
);

type Props = {
  dashboard: View,
  modalRef: () => ModalHandler,
};

export const InvestigationsModal = React.forwardRef(({ dashboard }: { dashboard: View }, ref: (r: ModalHandler) => void) => {
  const { security } = useLicenseCheck();

  return security?.isValid && (
    <IfPermitted permissions="investigations:edit">
      <AddEvidenceModal id={dashboard.id} type="dashboards" ref={ref} />
    </IfPermitted>
  );
});

const InvestigationsDashboardAction = ({ dashboard, modalRef }: Props) => {
  const { security } = useLicenseCheck();

  return security?.isValid && (
    <IfPermitted permissions="investigations:edit">
      <AddEvidence id={dashboard.id} type="dashboards" child={addToInvestigation} />
      <MenuItem icon="puzzle-piece" onClick={() => modalRef()?.toggle()}>
        Select an investigation
      </MenuItem>
    </IfPermitted>
  );
};

export default InvestigationsDashboardAction;
