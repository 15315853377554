import React, { useState } from 'react';

import { Wizard } from 'components/common';
import Routes from 'routing/Routes';
import useHistory from 'routing/useHistory';

import WelcomeStep from './WelcomeStep';
import SelectForwarderStep from './SelectForwarderStep';
import ConfigureForwarderStep from './ConfigureForwarderStep';
import AddInputsStep from './AddInputsStep';
import SummaryStep from './SummaryStep';
import ForwarderWizardProvider from './ForwarderWizardProvider';

const STEP_KEYS = ['welcome', 'select-forwarder', 'configure-forwarder', 'inputs', 'summary'];

type ForwarderWizardProps = {
  forwarderId?: string;
};

const ForwarderWizard = ({ forwarderId }: ForwarderWizardProps) => {
  const [activeStep, setActiveStep] = useState(forwarderId ? STEP_KEYS[2] : STEP_KEYS[0]);
  const history = useHistory();

  const introSteps = [
    {
      key: STEP_KEYS[0],
      title: (<>Welcome</>),
      component: <WelcomeStep onStepComplete={() => setActiveStep(STEP_KEYS[1])} />,
    },
    {
      key: STEP_KEYS[1],
      title: (<>Select Forwarder</>),
      component: (
        <SelectForwarderStep onStepComplete={() => setActiveStep(STEP_KEYS[2])} />
      ),
    },
  ];

  const configureSteps = [
    {
      key: STEP_KEYS[2],
      title: (<>Configure Forwarder</>),
      component: (
        <ConfigureForwarderStep forwarderId={forwarderId} onStepComplete={() => setActiveStep(STEP_KEYS[3])} />
      ),
    },
    {
      key: STEP_KEYS[3],
      title: (<>Add Inputs</>),
      component: (
        <AddInputsStep onStepComplete={() => setActiveStep(STEP_KEYS[4])} />
      ),
    },
    {
      key: STEP_KEYS[4],
      title: (<>Summary</>),
      component: (
        <SummaryStep hideConfigureAnother={!!forwarderId}
                     onWizardExit={() => history.push(Routes.pluginRoute('SYSTEM_FORWARDERS'))}
                     onWizardRestart={() => setActiveStep(STEP_KEYS[1])} />
      ),
    },
  ];

  const steps = forwarderId ? configureSteps : [...introSteps, ...configureSteps];

  return (
    <ForwarderWizardProvider>
      <Wizard steps={steps}
              activeStep={activeStep}
              onStepChange={setActiveStep}
              horizontal
              justified
              containerClassName=""
              hidePreviousNextButtons />
    </ForwarderWizardProvider>
  );
};

ForwarderWizard.defaultProps = {
  forwarderId: undefined,
};

export default ForwarderWizard;
