import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Immutable from 'immutable';
import styled from 'styled-components';

import { useStore } from 'stores/connect';
import { EntityList, IfPermitted, PaginatedList, SearchForm } from 'components/common';
import { Button } from 'components/bootstrap';

import ForwarderInputListItem from './ForwarderInputListItem';

import ForwarderInputTypesStore from '../stores/ForwarderInputTypesStore';
import { ForwarderInputsActions } from '../stores/ForwarderInputsStore';
import type { Forwarder, Input, InputFormContent, InputProfile, Pagination } from '../Types';
import { ForwarderPropType, ForwarderInputPropType, InputProfilePropType, PaginationPropType } from '../Types';
import InputForm from '../inputs/InputForm';
import { StyledSearchFormWrapper, StyledTitle } from '../wizard/StyledWizardComponents';

type Props = {
  forwarder?: Forwarder,
  inputProfile: InputProfile,
  inputs: Array<Input>,
  enableEdit: boolean,
  pagination: Pagination,
  onQueryChange: (page?: number, perPage?: number, query?: string) => void,
  onInputChange: () => void,
  onActionChange: (isShowing: boolean) => void,
};

const StyledButton = styled(Button)(() => `
  margin-left: 5px;
`);

const StyledFormWrapper = styled.div`
  margin-top: 10px;
`;

const ACTIONS = {
  NEW: 'new',
  LIST: 'list',
  EDIT: 'edit',
};

const ForwarderInputsManagement = ({
  forwarder,
  inputs,
  inputProfile,
  enableEdit,
  pagination,
  onQueryChange,
  onInputChange,
  onActionChange,
}: Props) => {
  const { total, page, perPage, query } = pagination;
  const [expandedInputDetails, setExpandedInputDetails] = useState(Immutable.Set<string>());
  const {
    forwarderInputDescriptions: inputDescriptions,
    forwarderInputTypes: inputTypes,
  } = useStore(ForwarderInputTypesStore);
  const [selectedInput, setSelectedInput] = useState(undefined);
  const [action, setAction] = useState(ACTIONS.LIST);

  const _toggleInputDetail = (id: string) => {
    let newSet;

    if (expandedInputDetails.contains(id)) {
      newSet = expandedInputDetails.delete(id);
    } else {
      newSet = expandedInputDetails.add(id);
    }

    setExpandedInputDetails(newSet);
  };

  const _showInputList = () => {
    setAction(ACTIONS.LIST);
    onActionChange(true);
  };

  const _handleInputCreate = (input: InputFormContent) => {
    ForwarderInputsActions.create(inputProfile.id, input)
      .then(() => {
        _showInputList();
        onInputChange();
      });
  };

  const _handleInputUpdate = (updatedInput: InputFormContent) => {
    if (!selectedInput) {
      throw new Error('No selected input when updating input. This should not happen at this stage.');
    }

    ForwarderInputsActions.update(inputProfile.id, selectedInput.id, updatedInput)
      .then(() => {
        _showInputList();
        onInputChange();
      });
  };

  const _handleInputDelete = (input: Input) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to delete input ${input.title}? This action cannot be undone.`)) {
      ForwarderInputsActions.delete(inputProfile.id, input)
        .then(() => {
          onInputChange();
        });
    }
  };

  const _handleSearch = (nextQuery: string) => onQueryChange(1, 10, nextQuery);

  const _handleSearchReset = () => onQueryChange();

  const _handlePaginationChange = (nextPage: number, nextPageSize: number) => {
    onQueryChange(nextPage, nextPageSize, query);
  };

  const _showEditForm = (input: Input) => {
    setSelectedInput(input);
    setAction(ACTIONS.EDIT);
    onActionChange(false);
  };

  const _showCreateForm = () => {
    setAction(ACTIONS.NEW);
    onActionChange(false);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const _formatInput = (input: Input) => {
    const { id: inputId, type: inputType } = input;

    return (
      <ForwarderInputListItem key={inputId}
                              forwarder={forwarder}
                              input={input}
                              inputDescription={inputDescriptions[inputType]}
                              onToggleDetails={_toggleInputDetail}
                              onDeleteInput={_handleInputDelete}
                              onEditInput={_showEditForm}
                              enableEdit={enableEdit}
                              expanded={expandedInputDetails.contains(inputId)} />
    );
  };

  const getInputsAction = () => {
    switch (action) {
      case ACTIONS.NEW:
        return (
          <StyledFormWrapper>
            <StyledTitle>Add Input to {inputProfile.title}</StyledTitle>
            <InputForm action="create"
                       inputTypes={inputTypes}
                       inputDescriptions={inputDescriptions}
                       onCancel={_showInputList}
                       onSubmit={_handleInputCreate} />
          </StyledFormWrapper>
        );

      // @ts-expect-error fallthrough is on purpose
      case ACTIONS.EDIT:
        if (selectedInput) {
          return (
            <StyledFormWrapper>
              <StyledTitle>
                Edit Input {selectedInput.title}
                <small> ({inputTypes[selectedInput.type]}) </small>
                in {inputProfile.title}
              </StyledTitle>
              <InputForm action="edit"
                         input={selectedInput}
                         inputTypes={inputTypes}
                         inputDescriptions={inputDescriptions}
                         onCancel={_showInputList}
                         onSubmit={_handleInputUpdate} />
            </StyledFormWrapper>
          );
        }

      // eslint-disable-next-line no-fallthrough
      default:
        return (
          <PaginatedList onChange={_handlePaginationChange}
                         totalItems={total}
                         showPageSizeSelect={false}
                         activePage={page}
                         pageSize={perPage}
                         useQueryParameter={false}>
            <StyledSearchFormWrapper>
              <SearchForm query={query}
                          onSearch={_handleSearch}
                          onReset={_handleSearchReset}
                          placeholder="Filter by title">
                {enableEdit ? (
                  <IfPermitted permissions="forwarderinputs:create">
                    <StyledButton onClick={_showCreateForm} bsStyle="success">
                      <span>Create input</span>
                    </StyledButton>
                  </IfPermitted>
                ) : null}
              </SearchForm>
            </StyledSearchFormWrapper>
            <EntityList bsNoItemsStyle="info"
                        noItemsText={inputs.length <= 0 ? 'There are no inputs configured for this Input Profile'
                          : 'No inputs match the filter'}
                        items={inputs.map((input) => _formatInput(input))} />
          </PaginatedList>
        );
    }
  };

  return (
    <>{getInputsAction()}</>
  );
};

ForwarderInputsManagement.propTypes = {
  forwarder: ForwarderPropType,
  inputs: PropTypes.arrayOf(ForwarderInputPropType),
  inputProfile: InputProfilePropType,
  enableEdit: PropTypes.bool,
  pagination: PaginationPropType.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  onActionChange: PropTypes.func,
};

ForwarderInputsManagement.defaultProps = {
  forwarder: undefined,
  inputs: [],
  inputProfile: {},
  enableEdit: false,
  onInputChange: () => {
  },
  onActionChange: () => {
  },
};

export default ForwarderInputsManagement;
