import * as React from 'react';

import Routes from 'routing/Routes';
import { IfPermitted } from 'components/common';
import PageContentLayout from 'components/layout/PageContentLayout';
import { Header, NavRow, NavTabLink, SectionHeading } from 'security-app/components/Layout';

type SigmaLayoutProps = {
  children: React.ReactNode;
};

export default function SigmaLayout({ children }: SigmaLayoutProps) {
  return (
    <PageContentLayout>
      <Header>
        <SectionHeading>Sigma Rules</SectionHeading>
        <NavRow>
          <NavTabLink to={Routes.pluginRoute('SECURITY_SIGMA')} end>Rules</NavTabLink>
          <IfPermitted permissions="sigma_repository:read">
            <NavTabLink to={Routes.pluginRoute('SECURITY_SIGMA_CONFIGURATION')}>Sigma Repos</NavTabLink>
          </IfPermitted>
        </NavRow>
      </Header>
      <div>{children}</div>
    </PageContentLayout>
  );
}
