import React, { useEffect } from 'react';

import Store from 'logic/local-storage/Store';
import useHistory from 'routing/useHistory';

const OidcCallbackPage = () => {
  const nonce = Store.sessionGet('nonce');
  const history = useHistory();

  useEffect(() => {
    history.replace(nonce.redirectTo || '/');
  }, [history, nonce]);

  useEffect(() => {
    return () => {
      Store.sessionDelete('nonce');
    };
  }, []);

  return <div>You have been successfully logged in. Please wait while we redirect your browser to the right page.</div>;
};

export default OidcCallbackPage;
