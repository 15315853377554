import * as React from 'react';
import { useRef } from 'react';

import type View from 'views/logic/views/View';
import { MenuItem } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import useLicenseCheck from 'license/useLicenseCheck';

import AddEvidence from './CoreAddEvidence';
import AddEvidenceModal from './shared/AddEvidenceModal';

const addToInvestigation = ({ investigationSelected }) => (
  <MenuItem disabled={!investigationSelected} icon="puzzle-piece">
    Add to investigation
  </MenuItem>
);

type Props = {
  loaded: boolean,
  view: View,
}

const InvestigationsSearchActions = ({ loaded, view }: Props) => {
  const addEvidenceModalRef = useRef(null);
  const { security } = useLicenseCheck();

  return (security?.isValid && loaded) ? (
    <IfPermitted permissions="investigations:edit">
      <AddEvidence id={view.id} type="searches" child={addToInvestigation} />
      <MenuItem icon="puzzle-piece"
                onClick={() => addEvidenceModalRef.current?.toggle()}>
        Select an investigation
      </MenuItem>
      <AddEvidenceModal id={view.id} type="searches" ref={addEvidenceModalRef} />
    </IfPermitted>
  ) : null;
};

export default InvestigationsSearchActions;
