import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';

import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { Button, FormGroup, InputGroup, FormControl, HelpBlock } from 'components/bootstrap';
import ColorPickerPopover from 'components/common/ColorPickerPopover';
import Icon from 'components/common/Icon';
import { getValueFromInput } from 'util/FormsUtils';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';

const ColorPickerPreview = styled.span<{ color: string }>(({ color }) => css`
  background-color: ${color};
  width: 10px;
  height: 10px;
  display: inherit;
  margin-right: 5px;
  margin-top: 3px;
`);

const DEFAULT = {
  badge_enable: false,
  badge_color: '#689f38',
  badge_text: '',
};

export type BadgeConfig = {
  badge_enable: boolean,
  badge_color: string,
  badge_text: string,
};

type Props = {
  config: BadgeConfig,
  updateConfig: (values: BadgeConfig) => void,
};

const HeaderBadgeConfiguration = ({ config, updateConfig }: Props) => {
  const currentUser = useCurrentUser();
  const sendTelemetry = useSendTelemetry();

  const _saveConfig = (values: BadgeConfig) => {
    sendTelemetry('form_submit', {
      app_pathname: 'event-notification',
      app_section: 'event-notification-bulk',
      app_action_value: 'delete-button',
    });

    updateConfig(values);
  };

  const validateBadgeText = (value: string) => {
    return value.length > 5 ? 'Should be maximum 5 characters long' : null;
  };

  const isDisabled = !isPermitted(currentUser.permissions, 'clusterconfigentry:edit');

  return (
    <Formik initialValues={{ ...DEFAULT, ...config }}
            onSubmit={_saveConfig}>
      {({ dirty, handleSubmit, handleChange, values, errors }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <FormGroup validationState={errors.badge_text ? 'warning' : null}>
              <InputGroup>
                <InputGroup.Addon>
                  <input type="checkbox"
                         id="badge-enable"
                         name="badge_enable"
                         data-testid="badge-enable"
                         checked={values.badge_enable}
                         onChange={(e) => {
                           handleChange(e);
                         }}
                         disabled={isDisabled} />
                </InputGroup.Addon>
                <Field name="badge_text" validate={validateBadgeText}>
                  {({ field: { name, value, onChange }, meta: { error } }) => (
                    <FormControl type="text"
                                 id="badge-text"
                                 data-testid="badge-text"
                                 name={name}
                                 label="Badge Text"
                                 value={value}
                                 onChange={(e) => onChange({ target: { name, value: getValueFromInput(e.target) } })}
                                 error={error}
                                 help={error}
                                 disabled={isDisabled} />
                  )}
                </Field>
                <Field name="badge_color">
                  {({ field: { name, value, onChange } }) => (
                    <InputGroup.Button>
                      <ColorPickerPopover id="badge-color"
                                          placement="left"
                                          color={value}
                                          triggerNode={(
                                            <Button disabled={isDisabled}>
                                              <ColorPickerPreview color={value} />
                                              <Icon name="paint-brush" /> Color
                                            </Button>
                                          )}
                                          onChange={(color, _, hidePopover) => {
                                            onChange({ target: { name, value: color } });
                                            hidePopover();
                                          }} />
                    </InputGroup.Button>
                  )}
                </Field>
              </InputGroup>

              <HelpBlock>{errors.badge_text ? errors.badge_text : 'The text of the badge. Not more than five characters recommended.'}</HelpBlock>

              {!isDisabled
                && <Button bsSize="xsmall" bsStyle="info" type="submit" disabled={!dirty}>Update Badge</Button>}
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

HeaderBadgeConfiguration.propTypes = {
  config: PropTypes.exact({
    badge_text: PropTypes.string.isRequired,
    badge_color: PropTypes.string.isRequired,
    badge_enable: PropTypes.bool.isRequired,
  }),
  updateConfig: PropTypes.func,
};

HeaderBadgeConfiguration.defaultProps = {
  updateConfig: () => {
  },
  config: DEFAULT,
};

export default HeaderBadgeConfiguration;
