import React, { createContext, useContext } from 'react';

import InvestigationDrawer from 'security-app/components/Investigations/Drawer';

const InvestigationDrawerContext = createContext(null);

type Props = {
  children: React.ReactNode,
};

export function InvestigationDrawerProvider({ children }: Props) {
  const [showDrawer, setShowDrawer] = React.useState<boolean>(false);
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const [selectedInvestigationId, setSelectedInvestigationId] = React.useState<string>(null);

  const contextValue = React.useMemo(() => ({
    collapsed,
    setCollapsed,
    setShowDrawer,
    selectedInvestigationId,
    setSelectedInvestigationId,
  }), [
    collapsed,
    setCollapsed,
    setShowDrawer,
    selectedInvestigationId,
    setSelectedInvestigationId,
  ]);

  return (
    <InvestigationDrawerContext.Provider value={contextValue}>
      {showDrawer && <InvestigationDrawer />}
      {children}
    </InvestigationDrawerContext.Provider>
  );
}

export function useInvestigationDrawer() {
  return useContext(InvestigationDrawerContext);
}
