import React, { createContext, useContext, useState } from 'react';

const PaginationContext = createContext(null);
const PaginationUpdateContext = createContext(null);

type Props = {
  children: React.ReactNode
};

export function PaginationProvider({ children }: Props) {
  const [localPagination, setLocalPagination] = useState({
    page: 1,
    perPage: 10,
    query: null,
    orderBy: null,
    direction: null,
    filters: {},
  });

  return (
    <PaginationContext.Provider value={localPagination}>
      <PaginationUpdateContext.Provider value={setLocalPagination}>
        {children}
      </PaginationUpdateContext.Provider>
    </PaginationContext.Provider>
  );
}

export function usePagination() {
  return useContext(PaginationContext);
}

export function useSetPagination() {
  return useContext(PaginationUpdateContext);
}
