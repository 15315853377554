import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';
import styled from 'styled-components';

import { Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import { ConfigurationFormField } from 'components/configurationforms';

import type { InputDescription } from '../Types';

const StyledButton = styled(Button)`
  margin-bottom: 20px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;
`;

type Props = {
  type: string,
  handleConfigurationChange: (fieldName: string, config: any, dirty?: boolean) => void,
  inputDescription: InputDescription,
  setDefaultValues: (defaultValues: any) => void,
  values: {
    [key: string]: any,
  },
  configurationFieldStates: {
    [key: string]: { dirty?: boolean }
  }
};

const InputConfigurationForm = ({ type, handleConfigurationChange, inputDescription, setDefaultValues, values, configurationFieldStates }: Props) => {
  const [displayOptionalFields, setDisplayOptionalFields] = useState(false);

  const configurationFields = inputDescription.requested_configuration;

  useEffect(() => {
    if (!values || Object.keys(values).length === 0) {
      const defaultValues = mapValues(configurationFields, (configuration) => configuration.default_value);
      setDefaultValues(defaultValues);
    }
  }, [type, configurationFields, values, setDefaultValues]);

  const mandatoryFields = Object.keys(configurationFields).filter((field) => !configurationFields[field].is_optional);
  const optionalFields = Object.keys(configurationFields).filter((field) => configurationFields[field].is_optional);

  const renderField = (field: string) => {
    return (
      <ConfigurationFormField key={field}
                              typeName={type}
                              configField={configurationFields[field]}
                              configKey={field}
                              configValue={values[field] || ''}
                              autoFocus={false}
                              dirty={configurationFieldStates[field] && configurationFieldStates[field].dirty}
                              onChange={handleConfigurationChange} />
    );
  };

  return (
    <>
      {mandatoryFields.map(renderField)}
      <StyledButton bsStyle="info" onClick={() => setDisplayOptionalFields(!displayOptionalFields)}>
        <StyledIcon name={displayOptionalFields ? 'chevron-down' : 'chevron-right'} />
        {displayOptionalFields ? 'Hide' : 'Show'} optional fields
      </StyledButton>
      {displayOptionalFields && optionalFields.map(renderField)}
    </>
  );
};

InputConfigurationForm.propTypes = {
  type: PropTypes.string.isRequired,
  handleConfigurationChange: PropTypes.func.isRequired,
  inputDescription: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    is_exclusive: PropTypes.bool,
    requested_configuration: PropTypes.objectOf(PropTypes.any).isRequired,
    link_to_docs: PropTypes.string,
  }).isRequired,
  setDefaultValues: PropTypes.func.isRequired,
  configurationFieldStates: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any),
};

InputConfigurationForm.defaultProps = {
  values: {},
};

export default InputConfigurationForm;
