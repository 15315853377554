import * as React from 'react';

import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useAddEvidence } from 'security-app/hooks/useInvestigationsAPI';

type Props = {
  type: 'logs' | 'dashboards' | 'searches' | 'events',
  id: string,
  index?: string,
  children: React.ReactElement,
};

function AddEvidence({ type, id, index, children }: Props) {
  const { addEvidence } = useAddEvidence();
  const { selectedInvestigationId } = useInvestigationDrawer();

  const submitEvidence = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();

    switch (type) {
      case 'logs':
        await addEvidence({
          invId: selectedInvestigationId,
          payload: { logs: [{ id, index }] },
        });

        break;
      case 'dashboards':
      case 'searches':
      case 'events':
        await addEvidence({
          invId: selectedInvestigationId,
          payload: { [type]: [id] },
        });

        break;
      default:
        break;
    }
  };

  const childWithProps = React.cloneElement(React.Children.only(children), { onClick: submitEvidence });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{childWithProps}</>;
}

AddEvidence.defaultProps = {
  index: null,
};

export default AddEvidence;
