import loadAsync from 'routing/loadAsync';

const Anomaly = loadAsync(() => import('security-app/pages/anomaly/Anomalies'));
const Configuration = loadAsync(() => import('security-app/pages/anomaly/DetectorConfiguration'));
const HostActivity = loadAsync(() => import('security-app/pages/HostActivity'));
const NetworkActivity = loadAsync(() => import('security-app/pages/NetworkActivity'));
const Overview = loadAsync(() => import('security-app/pages/Overview'));
const UserActivity = loadAsync(() => import('security-app/pages/UserActivity'));
const Sigma = loadAsync(() => import('security-app/pages/sigma/Sigma'));
const SigmaConfig = loadAsync(() => import('security-app/pages/sigma/SigmaConfig'));
const AnomalyDetectionEdit = loadAsync(() => import('security-app/pages/anomaly/AnomalyDetectionEdit'));
const DetectorConfiguration = loadAsync(() => import('security-app/pages/anomaly/DetectorConfiguration'));
const Investigations = loadAsync(() => import('security-app/pages/investigations/Investigations'));
const InvestigationsConfig = loadAsync(() => import('security-app/pages/investigations/InvestigationsConfig'));

export {
  Anomaly,
  Configuration,
  DetectorConfiguration,
  HostActivity,
  NetworkActivity,
  Overview,
  UserActivity,
  Sigma,
  SigmaConfig,
  AnomalyDetectionEdit,
  Investigations,
  InvestigationsConfig,
};
