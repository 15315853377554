import { filterCloudMenuItems } from 'util/conditional/filterMenuItems';
import { IlluminateDropdownBadge, IlluminateLinkBadge } from 'common/components';

export default {
  navigation: [
    {
      description: 'Enterprise',
      children: filterCloudMenuItems(
        [
          { path: '/system/enterprise', description: 'Overview', permissions: 'licenseinfos:read' },
          { path: '/system/archives', description: 'Archives', permissions: 'archive:read' },
          { path: '/system/auditlog', description: 'Audit Log', permissions: 'auditlog_status:read' },
          { path: '/system/licenses', description: 'Licenses', permissions: 'licenses:read' },
          { path: '/reports', description: 'Reports', permissions: 'report:update' },
          { path: '/customization', description: 'Customization', permissions: 'clusterconfig:read' },
          { path: '/system/forwarders', description: 'Forwarders', permissions: 'forwarders:edit' },
          { path: '/illuminate', description: 'Illuminate', permissions: '*', BadgeComponent: IlluminateLinkBadge },
          { path: '/my-filters', description: 'My Search Filters', requiredFeatureFlag: 'search_filter' },
        ],
        ['/system/licenses', '/system/forwarders'],
      ),
      BadgeComponent: IlluminateDropdownBadge,
    },
    {
      description: 'Security',
      path: '/security',
      permissions: 'graylog_security:read',
    },
  ],
};
