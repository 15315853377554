import styled from 'styled-components';

const FabButton = styled.button`
  transition: all 0.33s ease-in-out;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  color: ${({ theme }) => theme.colors.gray[60]};
  font-size: ${({ theme }) => theme.fonts.size.extraLarge};
  padding: 0.5rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray[80]};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.gray[70]};
  }
`;

export default FabButton;
