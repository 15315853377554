import * as React from 'react';
import { useState, useEffect } from 'react';

import { DataTable, PaginatedList, Spinner, NoSearchResult } from 'components/common';
import { Alert } from 'components/bootstrap';
import SectionComponent from 'components/common/Section/SectionComponent';
import type AuthenticationBackend from 'logic/authentication/AuthenticationBackend';

import SyncedTeamsOverviewItem from './SyncedTeamsOverviewItem';
import SyncedTeamsFilter from './SyncedTeamsFilter';

import type { PaginatedAuthTeams } from '../actions/AuthenticationActions';
import LicenseCheck from '../../license/LicenseCheck';
import { AuthenticationActions } from '../stores/AuthenticationStore';

const DEFAULT_PAGINATION = {
  page: 1,
  perPage: 10,
  query: '',
};

const TABLE_HEADERS = ['Name', 'Users', 'Roles'];

const _headerCellFormatter = (header) => {
  switch (header.toLocaleLowerCase()) {
    case 'actions':
      return <th className="actions text-right">{header}</th>;
    default:
      return <th>{header}</th>;
  }
};

const _loadTeams = (authBackendId, pagination, setLoading, setPaginatedTeams) => {
  setLoading(true);

  AuthenticationActions.loadTeamsPaginated(authBackendId, pagination).then((paginatedTeams) => {
    setPaginatedTeams(paginatedTeams);
    setLoading(false);
  });
};

const _updateListOnTeamSync = (perPage, query, setPagination) => AuthenticationActions.triggerTeamSync.completed.listen(() => setPagination({ page: DEFAULT_PAGINATION.page, perPage, query }));

type Props = {
  authenticationBackend: AuthenticationBackend,
};

const SyncedTeamsSection = ({ authenticationBackend }: Props) => {
  const [loading, setLoading] = useState(false);
  const [paginatedTeams, setPaginatedTeams] = useState<PaginatedAuthTeams | null | undefined>();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const { page, perPage, query } = pagination;
  const { config: { type } } = authenticationBackend;
  useEffect(() => _loadTeams(authenticationBackend.id, pagination, setLoading, setPaginatedTeams), [authenticationBackend.id, pagination]);
  useEffect(() => _updateListOnTeamSync(perPage, query, setPagination), [perPage, query]);

  if (type === 'oidc') {
    return (
      <SectionComponent title="Synchronized Teams">
        <Alert>
          This authentication service does not support group synchronization.
        </Alert>
      </SectionComponent>
    );
  }

  if (!paginatedTeams) {
    return <Spinner />;
  }

  const searchFilter = <SyncedTeamsFilter onSearch={(newQuery) => setPagination({ ...pagination, query: newQuery, page: DEFAULT_PAGINATION.page })} />;

  return (
    <SectionComponent title="Synchronized Teams" showLoading={loading}>
      <LicenseCheck>
        <p className="description">
          Found {paginatedTeams.pagination.total} synchronized teams.
        </p>
        <PaginatedList activePage={page}
                       totalItems={paginatedTeams.pagination.total}
                       onChange={(newPage, newPerPage) => setPagination({ ...pagination, page: newPage, perPage: newPerPage })}
                       useQueryParameter={false}>
          <DataTable className="table-hover"
                     customFilter={searchFilter}
                     // eslint-disable-next-line react/no-unstable-nested-components
                     dataRowFormatter={(team) => <SyncedTeamsOverviewItem team={team} rolesContext={paginatedTeams.context.roles} usersContext={paginatedTeams.context.users} />}
                     filterKeys={[]}
                     filterLabel="Filter Teams"
                     headerCellFormatter={_headerCellFormatter}
                     headers={TABLE_HEADERS}
                     id="synced-teams-overview"
                     noDataText={<NoSearchResult>No synchronized teams have been found.</NoSearchResult>}
                     rowClassName="no-bm"
                     rows={paginatedTeams.list.toJS()}
                     sortByKey="name" />
        </PaginatedList>
      </LicenseCheck>
    </SectionComponent>
  );
};

export default SyncedTeamsSection;
