import type { PluginExports } from 'graylog-web-plugin/plugin';

import { SecurityLayout } from 'security-app/components/Layout';
import {
  Anomaly,
  DetectorConfiguration,
  HostActivity,
  NetworkActivity,
  UserActivity,
  Overview,
  Sigma,
  SigmaConfig,
  AnomalyDetectionEdit,
  Investigations,
  InvestigationsConfig,
} from 'security-app/pages';

import { InvestigationDrawerProvider } from './components/Investigations/contexts';
import investigationsPlugin from './components/Investigations/plugin';

const allRoutes = [
  { path: '/security', component: Overview, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/user-activity', component: UserActivity, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/host-activity', component: HostActivity, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/network-activity', component: NetworkActivity, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/anomalies', component: Anomaly, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/anomalies/detectors', component: DetectorConfiguration, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/anomalies/detector/create', component: AnomalyDetectionEdit, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/anomalies/detector/edit/:id', component: AnomalyDetectionEdit, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/sigma', component: Sigma, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/sigma/configuration', component: SigmaConfig, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/investigations', component: Investigations, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/investigations/archived', component: Investigations, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/investigations/configuration', component: InvestigationsConfig, permissions: '*', parentComponent: SecurityLayout },
];

const pluginExports: PluginExports = {
  routes: allRoutes,
  globalContextProviders: [
    InvestigationDrawerProvider,
  ],
  ...investigationsPlugin,
};

export default pluginExports;
