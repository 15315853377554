import React from 'react';

import { NoSearchResult, NoEntitiesExist } from 'components/common';

type Props = {
  noSearchResultText: string,
  noEntitiesExist: string
  colSpan: number,
  query?: string,
  filters?: { [key: string]: string },
};

const EmptyList = ({ noSearchResultText, noEntitiesExist, colSpan, query, filters }: Props) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        {query || Object.keys(filters).length > 0
          ? <NoSearchResult> {noSearchResultText}</NoSearchResult>
          : <NoEntitiesExist> {noEntitiesExist}</NoEntitiesExist>}
      </td>
    </tr>
  );
};

EmptyList.defaultProps = {
  query: null,
  filters: {},
};

export default EmptyList;
