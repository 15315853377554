import * as React from 'react';

import { Popover, Table } from 'components/bootstrap';

const ReportQueryHelper = () => (
  <Popover id="search-query-help"
           className="popover-wide"
           title="Search Syntax Help"
           data-app-section="report_query_helper"
           data-event-element="Available search fields">
    <p><strong>Available search fields</strong></p>
    <Table condensed>
      <thead>
        <tr>
          <th>Field</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>title</td>
          <td>The title of the report</td>
        </tr>
        <tr>
          <td>subtitle</td>
          <td>The subtitle of the report</td>
        </tr>
        <tr>
          <td>description</td>
          <td>The description of the report</td>
        </tr>
      </tbody>
    </Table>
    <p><strong>Examples</strong></p>
    <p>
      Find report containing critical in the description:<br />
      <kbd>pattern:critical</kbd><br />
    </p>
  </Popover>
);

export default ReportQueryHelper;
