import InvestigationsMessageAction from './CoreMessageActions';
import InvestigationsSearchActions from './CoreSearchActions';
import InvestigationsEventAction from './CoreEventActions';
import InvestigationsDashboardAction, { InvestigationsModal } from './CoreDashboardActions';

export default {
  'views.components.dashboardActions': [{ component: InvestigationsDashboardAction, key: 'investigations', modal: InvestigationsModal }],
  'views.components.eventActions': [InvestigationsEventAction],
  'views.components.widgets.messageTable.messageActions': [InvestigationsMessageAction],
  'views.components.searchActions': [InvestigationsSearchActions],
};
