import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import { DropdownMenu } from 'components/common';

export const Backdrop = styled.div(({ $show }: { $show: boolean}) => css`
  display: ${$show ? 'block' : 'none'};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  z-index: 50;
  cursor: default;
`);

type DropDownProps = {
  theme: DefaultTheme,
  $posx: string,
  $posy: string,
};

export const BrandDropdown = styled(DropdownMenu)(({ theme, $posx, $posy }: DropDownProps) => css`
  left: ${$posx === 'right' ? 0 : 'unset'};
  right: ${$posx === 'left' ? 0 : 'unset'};
  top: ${$posy === 'bottom' ? '80%' : 'unset'};
  bottom: ${$posy === 'top' ? '80%' : 'unset'};

  border: 1px solid ${theme.colors.brand.tertiary};

  & li button {
    background: none;
    border: none;
    padding: 6px;
    min-width: 95px;
    text-align: left;
    width: 100%;
    color: ${theme.colors.brand.tertiary};

    &:hover {
      background-color: ${theme.colors.global.contentBackground};
    }
  }
`);
